import createUserUseCase from "@/core/domain/usecases/user/create-user";
import downloadUploadUserTemplateUseCase from "@/core/domain/usecases/user/download-upload-user-template";
import getUserDetailUseCase from "@/core/domain/usecases/user/get-user-detail";
import getUserListUseCase from "@/core/domain/usecases/user/get-user-list";
import removeUserUseCase from "@/core/domain/usecases/user/remove-user";
import sendEmailUseCase from "@/core/domain/usecases/user/send-email";
import updateUserUseCase from "@/core/domain/usecases/user/update-user";
import uploadAvatarUseCase from "@/core/domain/usecases/user/upload-avatar";
import router from "@/router";
import { ALERT_ERROR, ALERT_SUCCESS } from "../../actions/alert";

export default {
  async getUserList({ commit, dispatch }, query) {
    commit("GET_USER_FETCHING");
    const result = await getUserListUseCase(query);
    if (result.isSuccess) {
      return commit("GET_USER_SUCCESS", {
        total: result.data.total,
        pageSize: result.data.limit,
        data: result.data.items
      });
    }
    dispatch(ALERT_ERROR, result.message, { root: true });
    return commit("GET_USER_ERROR", result.message);
  },
  async getUserDetail({ commit, dispatch }, id) {
    if (!id) return commit("GET_USER_DETAIL_SUCCESS", {});

    commit("GET_USER_DETAIL_FETCHING");
    const result = await getUserDetailUseCase(id);
    if (result.isSuccess) {
      return commit("GET_USER_DETAIL_SUCCESS", result.data);
    }

    dispatch(ALERT_ERROR, result.message, { root: true });
    return commit("GET_USER_DETAIL_ERROR", result.message);
  },
  async removeUser({ commit, dispatch }, id) {
    commit("SET_USER_LOADING");
    const result = await removeUserUseCase(id);
    if (result.isSuccess) {
      dispatch(ALERT_SUCCESS, result.message, { root: true });
      return commit("REMOVE_USER_SUCCESS", id);
    }

    dispatch(ALERT_ERROR, result.message, { root: true });
    return commit("REMOVE_USER_ERROR", result.message);
  },
  async updateUser({ commit, dispatch }, data) {
    commit("SET_USER_LOADING");
    const result = await updateUserUseCase(data);
    if (result.isSuccess) {
      dispatch(ALERT_SUCCESS, result.message, { root: true });
      return commit("UPDATE_USER_SUCCESS", result.data);
    }

    dispatch(ALERT_ERROR, result.message, { root: true });
    return commit("UPDATE_USER_ERROR", result.message);
  },
  async createUser({ commit, dispatch }, data) {
    commit("SET_USER_LOADING");

    const result = await createUserUseCase(data);
    if (result.isSuccess) {
      dispatch(ALERT_SUCCESS, result.message, { root: true });
      commit("CREATE_USER_SUCCESS", result.data);
      router.back();
      return;
    }

    dispatch(ALERT_ERROR, result.message, { root: true });
    return commit("CREATE_USER_ERROR", result.message);
  },
  async sendEmail({ dispatch, commit }, id) {
    commit("SET_USER_LOADING");

    const result = await sendEmailUseCase(id);
    if (result.isSuccess) dispatch(ALERT_SUCCESS, result.message, { root: true });
    else dispatch(ALERT_ERROR, result.message, { root: true });

    commit("SEND_USER_EMAIL");
  },
  async uploadAvatar({ dispatch, commit }, data) {
    const result = await uploadAvatarUseCase(data);
    if (result.isSuccess) {
      commit("UPLOAD_AVATAR_SUCCESS", result.data);
      dispatch(ALERT_SUCCESS, "Thành công", { root: true });
      return;
    }

    commit("UPLOAD_AVATAR_ERROR", result.data);
    dispatch(ALERT_ERROR, result.message, { root: true });
  },
  async downloadUploadUserTemplate({ dispatch }, id) {
    const result = await downloadUploadUserTemplateUseCase(id);
    if (result.isSuccess) dispatch(ALERT_SUCCESS, result.message, { root: true });
    else dispatch(ALERT_ERROR, result.message, { root: true });
  }
};
