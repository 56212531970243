import router from "@/router";
import store from "@/store";

import { routes } from "@/router/routes";
import { NOTIFICATION_TYPE } from "../models/notification-model";

export const handleNotificationSelect = (item) => {
  if (item.type === NOTIFICATION_TYPE.contact) {
    const isSuperAdmin = store.getters["account/isSuperAdmin"];
    const isAdmin = store.getters["account/isAdmin"];
    const loginAccount = store.getters["account/loginAccount"];

    const query = { activeTab: "2" };
    if (isSuperAdmin) {
      router.push({ name: routes.report.name, query });
    } else if (isAdmin) {
      router.push({
        name: routes.organizationReport.name,
        params: { orgId: loginAccount.organization },
        query
      });
    } else {
      router.push({ name: routes.userReport.name, params: { userId: loginAccount.id }, query });
    }
  }
};
