import { DEFAULT_AVATAR_URL, ECARD_PROFILE } from "@/config";
import { convertSocialUrl } from "@/core/domain/helpers/social-helper";
import { isMobile } from "@/core/utils/device";
import { generateQRCode } from "@/core/utils/qrcode";
import { getCompanyState } from "./company-model";
// import { getCompanyState } from "./company-model";

export const SHARE_SOCIAL = {
  message: "message",
  facebook: "facebook",
  linkedIn: "linkedIn",
  twitter: "twitter",
  pinterest: "pinterest",
  zalo: "zalo",
  whatsapp: "whatsapp",
  telegram: "telegram"
};

export const getProfileUrl = (id = 0, query) => {
  let url = `${ECARD_PROFILE.replace(":userId", id)}`;
  if (query?.social) {
    url = url.concat(`?utm_social=${query.social}`);
  }

  return url;
};

export const profileModel = (values = {}, view = true) => {
  const url = getProfileUrl(values.id);
  const organizationInfo = view
    ? getCompanyState(values.userType, values.organizationInfo, values.company)
    : values.organizationInfo;

  const orgSocial =
    organizationInfo?.social?.filter((item) => item.code && item.url && item.active === true) || [];
  const userSocial =
    values && values.social
      ? values.social.filter((item) => item.code && item.url && item.active === true)
      : [];

  const orgVideo =
    (values &&
      organizationInfo?.video?.items?.filter((item) => item.url && item.active === true)) ||
    [];
  const userVideo =
    values && values.video?.items
      ? values.video.items.filter((item) => item.url && item.active === true)
      : [];

  const orgBlog =
    (values &&
      organizationInfo?.blog?.items?.filter((item) => item.title && item.active === true)) ||
    [];
  const userBlog =
    values && values.blog?.items
      ? values.blog.items.filter((item) => item.title && item.active === true)
      : [];

  const social = [].concat(orgSocial, userSocial);
  const video = [].concat(orgVideo, userVideo);
  const blog = [].concat(orgBlog, userBlog);
  const messageShareUrl = getProfileUrl(values.id, { social: SHARE_SOCIAL.message });
  let shareToMessage = `https://www.facebook.com/dialog/send?app_id=2101792139943903&link=${messageShareUrl}&redirect_uri=${messageShareUrl}`;

  if (isMobile()) shareToMessage = `fb-messenger://share/?link=${messageShareUrl}`;

  return {
    selectTemplate: values.selectTemplate,
    id: values.id || "",
    email: values.email ? `mailto:${values.email}` : "",
    phone: values.phone ? `tel:${values.phone}` : "",
    name: values.name || "",
    avatar: values.avatar || DEFAULT_AVATAR_URL,
    title: values.title || "",
    description: values.description || "",
    video: { items: video, active: values.video?.active },
    blog: { items: blog, active: values.blog?.active },
    social: convertSocialUrl(social),
    organization: values.organization,
    organizationInfo: {
      ...organizationInfo,
      video: { ...organizationInfo?.video, items: video },
      blog: { ...organizationInfo?.blog, items: blog }
    },
    userType: values.userType,
    address: values.address,
    map: values.address ? `https://maps.google.com/maps?q=${values.address}` : "",
    qrcode: generateQRCode(values.cardId),
    url,
    shareToFb: `https://www.facebook.com/sharer/sharer.php?u=${getProfileUrl(values.id, {
      social: SHARE_SOCIAL.facebook
    })}`,
    shareToMessage,
    shareToLinkedIn: `https://www.linkedin.com/sharing/share-offsite/?url=${getProfileUrl(
      values.id,
      { social: SHARE_SOCIAL.linkedIn }
    )}`,
    shareToTwitter: `https://twitter.com/intent/tweet?url=${getProfileUrl(values.id, {
      social: SHARE_SOCIAL.twitter
    })}`,
    shareToPinterest: `http://pinterest.com/pin/create/link/?url=${getProfileUrl(values.id, {
      social: SHARE_SOCIAL.pinterest
    })}`,
    shareToZalo: getProfileUrl(values.id, { social: SHARE_SOCIAL.zalo }),
    shareToWhatsapp: `https://api.whatsapp.com/send?&text=${getProfileUrl(values.id, {
      social: SHARE_SOCIAL.whatsapp
    })}`,
    shareToTelegram: `https://telegram.me/share/url?url=${getProfileUrl(values.id, {
      social: SHARE_SOCIAL.telegram
    })}`,
    exchangeContactStatus: values.exchangeContactStatus,
    leadFormConfig: values.leadFormConfig
  };
};
