import { API_HEADERS, API_HOST } from "@/config";
import apiCode from "../services/api-services/api-code";
import ApiService from "../services/api-services/api-service";

class UserApi {
  #apiService = new ApiService(API_HOST, API_HEADERS);

  async getUserListPagination(query) {
    const result = await this.#apiService.get("/user/pagination", query);

    return {
      isSuccess: result.code === apiCode.success,
      status: result.status,
      code: result.code,
      message: result.message,
      data: {
        items: result.data.items || [],
        total: Number(result.data.total) || 0,
        limit: Number(result.data.limit)
      }
    };
  }

  async getUserList(query) {
    const result = await this.#apiService.get("/user", query);
    return {
      isSuccess: result.code === apiCode.success,
      status: result.status,
      code: result.code,
      message: result.message,
      data: {
        items: result.data.items || []
      }
    };
  }

  async getUserDetail(id, query) {
    const result = await this.#apiService.get(`/user/detail/${id}`, query);
    return {
      isSuccess: result.code === apiCode.success,
      status: result.status,
      code: result.code,
      message: result.message,
      data: result.data
    };
  }

  async createUser(body) {
    const result = await this.#apiService.post("/user", body);
    return {
      isSuccess: result.code === apiCode.success,
      status: result.status,
      code: result.code,
      message: result.message,
      data: result.data
    };
  }

  async updateUser(id, body) {
    const result = await this.#apiService.put(`/user/${id}`, body);
    return {
      isSuccess: result.code === apiCode.success,
      status: result.status,
      code: result.code,
      message: result.message,
      data: result.data
    };
  }

  async updateDeviceInfo(id) {
    const result = await this.#apiService.put(`/user/${id}/device-info`);
    return {
      isSuccess: result.code === apiCode.success,
      status: result.status,
      code: result.code,
      message: result.message,
      data: result.data
    };
  }

  async removeUser(id) {
    const result = await this.#apiService.remove(`/user/${id}`);
    return {
      ...{ isSuccess: result.code === apiCode.success },
      ...result
    };
  }

  async login(body) {
    let result = await this.#apiService.post("/user/login", body);

    return {
      isSuccess: result.code === apiCode.success,
      status: result.status,
      code: result.code,
      message: result.message,
      data: { token: result.data.token, user: result.data.user }
    };
  }

  async logout(id) {
    const result = await this.#apiService.put(`/user/${id}/logout`);

    return {
      isSuccess: result.code === apiCode.success,
      status: result.status,
      code: result.code,
      message: result.message,
      data: result.data
    };
  }

  async changePassword(id, body) {
    const result = await this.#apiService.put(`/user/${id}/change-password`, body);

    return {
      isSuccess: result.code === apiCode.success,
      status: result.status,
      code: result.code,
      message: result.message,
      data: result.data
    };
  }

  async register(body) {
    const result = await this.#apiService.post("/user/register", body);

    return {
      isSuccess: result.code === apiCode.success,
      status: result.status,
      code: result.code,
      message: result.message,
      data: result.data
    };
  }

  async forgotPassword(body) {
    const result = await this.#apiService.post("/user/forgot-password", body);

    return {
      isSuccess: result.code === apiCode.success,
      status: result.status,
      code: result.code,
      message: result.message,
      data: result.data
    };
  }

  async resetPassword(body) {
    const result = await this.#apiService.post("/user/reset-password", body);

    return {
      isSuccess: result.code === apiCode.success,
      status: result.status,
      code: result.code,
      message: result.message,
      data: result.data
    };
  }

  async sendEmail(userId) {
    const result = await this.#apiService.put(`/user/${userId}/send-email`);

    return {
      isSuccess: result.code === apiCode.success,
      status: result.status,
      code: result.code,
      message: result.message,
      data: result.data
    };
  }

  async downloadUploadUserTemplate() {
    return await this.#apiService.save(
      "/user/files/upload-template",
      "GET",
      "upload-template.xlsx"
    );
  }

  async uploadAvatar(body) {
    const result = await this.#apiService.upload("/user/upload/avatar", body);

    return {
      isSuccess: result.data.id && result.data.url ? true : false,
      data: result.data
    };
  }
}

export default UserApi;
