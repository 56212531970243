import getDashboardUseCase from "@/core/domain/usecases/dashboard/get-dashboard";
import { ALERT_ERROR } from "../../actions/alert";

export default {
  async getDashboard({ commit, dispatch }, query) {
    commit("GET_DASHBOARD_FETCHING");
    const result = await getDashboardUseCase(query);
    if (result.isSuccess) return commit("GET_DASHBOARD_SUCCESS", result.data);

    dispatch(ALERT_ERROR, result.message, { root: true });
    return commit("GET_DASHBOARD_ERROR", result.message);
  }
};
