<template>
  <div>
    <div class="float-button-wrapper" @click="handleCollapse">
      <div class="float-button-menu" v-show="!collapse">
        <slot />
      </div>
      <div class="float-button">
        <img :src="icon" />
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from "vue";
import { CLOSE_IC, PLUS_IC } from "../icons/path";

export default defineComponent({
  props: ["collapse"],
  emits: ["update:collapse"],
  setup(props, { emit }) {
    const icon = ref(PLUS_IC);
    const handleCollapse = () => {
      emit("update:collapse", !props.collapse);

      if (props.collapse)
        return icon.value = CLOSE_IC;

      return icon.value = PLUS_IC;
    };

    return {
      icon,
      handleCollapse
    };
  }
});
</script>
