<template>
  <div class="notification-ic">
    <a-dropdown v-model:visible="visible" :trigger="['click', 'hover']">
      <div>
        <img :src="NOTIFICATION_IC" />
        <span class="notification-count">{{ unreadCount }}</span>
      </div>
      <template #overlay>
        <a-menu class="notification-dropdown-overlay">
          <a-row class="notification-text-wrapper" justify="space-between">
            <span>{{ t("page.notification.title") }}</span>
            <img :src="CLOSE_IC" @click="visible = false" />
          </a-row>
          <a-list class="notification-list" item-layout="horizontal" :data-source="notification">
            <template #renderItem="{ item }">
              <div :class="item.isRead ? 'read-item' : 'unread-item'" @click="() => selectNotification(item)">
                <div class="notification-item">
                  <NotificationIcon :type="item.type" :is-read="item.isRead" />
                  <div>
                    <span class="notification-content" v-html="item.body" />
                    <span class="notification-description">{{ item.createdText }} - {{ item.title }}</span>
                  </div>
                </div>
              </div>
            </template>
          </a-list>
          <router-link :to="routes.notification">
            <div class="notification-view-all">
              <span>{{ t("action.seeAll") }}</span>
              <img :src="ARROW_RIGHT_IC" />
            </div>
          </router-link>
        </a-menu>
      </template>
    </a-dropdown>
  </div>
</template>

<script setup>
import NotificationIcon from "@/components/notification/NotificationIcon.vue";

import {
  ARROW_RIGHT_IC,
  CLOSE_IC,
  NOTIFICATION_IC
} from "@/components/icons/path";
import { handleNotificationSelect } from "@/core/domain/helpers/notification-helper";
import { GET_NOTIFICATION_LIST, SET_READ_NOTIFICATION } from "@/core/presentation/actions/notification";
import { routes } from "@/router/routes";
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";

const { t } = useI18n();
const store = useStore();
const loginAccount = computed(() => store.state.account.loginAccount);

store.dispatch(GET_NOTIFICATION_LIST, { userId: loginAccount.value.id });

const notification = computed(() => store.state.notification.data);
const unreadCount = computed(() => store.state.notification.unreadCount);
const selectNotification = (item) => {
  if (!item.isRead) store.dispatch(SET_READ_NOTIFICATION, item.id);
  handleNotificationSelect(item);
};

const visible = ref(false);
</script>

<style scoped>
.notification-dropdown-overlay {
  width: 30vw;
  padding: 0;
  border-radius: 4px;
  margin-top: 8px;
}

.notification-text-wrapper {
  padding: 10px 14px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  background: #ffffff;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.07);
  border-radius: 4px 4px 0px 0px;
}

.notification-text-wrapper {
  padding: 10px 14px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
}

.notification-text-wrapper img {
  height: 20px;
  width: 20px;
  filter: invert(55%) sepia(0%) saturate(4899%) hue-rotate(50deg) brightness(79%) contrast(75%);
}

@media only screen and (max-width: 991px) {
  .notification-dropdown-overlay {
    width: 100vw;
  }
}
</style>
