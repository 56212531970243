import createNotificationUseCase from "@/core/domain/usecases/notification/create-notification";
import getNotificationListUseCase from "@/core/domain/usecases/notification/get-notification-list";
import setReadNotificationUseCase from "@/core/domain/usecases/notification/set-read-notification";
import { ALERT_ERROR, ALERT_SUCCESS } from "../../actions/alert";

export default {
  async getNotificationList({ commit, dispatch }, query) {
    commit("GET_NOTIFICATION_FETCHING");
    const result = await getNotificationListUseCase(query);
    if (result.isSuccess) {
      return commit("GET_NOTIFICATION_SUCCESS", {
        total: result.data.total,
        pageSize: result.data.limit,
        data: result.data.items,
        unreadCount: result.data.unreadCount
      });
    }
    dispatch(ALERT_ERROR, result.message, { root: true });
    return commit("GET_NOTIFICATION_ERROR", result.message);
  },
  async getMoreNotification({ commit, dispatch }, query) {
    commit("SET_NOTIFICATION_LOADING");
    const result = await getNotificationListUseCase(query);
    if (result.isSuccess) {
      return commit("GET_MORE_NOTIFICATION_SUCCESS", { data: result.data.items });
    }
    dispatch(ALERT_ERROR, result.message, { root: true });
    return commit("GET_NOTIFICATION_ERROR", result.message);
  },
  async createNotification({ commit, dispatch }, data) {
    commit("SET_NOTIFICATION_LOADING");

    const result = await createNotificationUseCase(data);
    if (result.isSuccess) {
      dispatch(ALERT_SUCCESS, result.message, { root: true });
      return commit("CREATE_NOTIFICATION_SUCCESS", result.data);
    }

    dispatch(ALERT_ERROR, result.message, { root: true });
    return commit("CREATE_NOTIFICATION_ERROR", result.message);
  },
  async setReadNotification({ commit }, id) {
    const result = await setReadNotificationUseCase(id);
    if (result.isSuccess) {
      return commit("SET_READ_NOTIFICATION_SUCCESS", result.data);
    }
    return commit("SET_READ_NOTIFICATION_ERROR", result.message);
  },
  async getNewNotification({ commit }, notification) {
    commit("GET_NEW_NOTIFICATION_SUCCESS", notification);
  },
  async setNewNotificationVisible({ commit }, visible) {
    return commit("SET_NEW_NOTIFICATION_VISIBLE", visible);
  }
};
