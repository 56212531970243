import { ACTION } from "@/core/utils/constant";

const subject = { template: "template", user: "user" };

export const defaultRules = (rules = []) => {
  return [
    {
      label: "Danh mục template",
      subject: "template",
      actions: [
        {
          key: ACTION.create,
          value: rules?.find(
            (item) => item.actions === ACTION.create && item.subject === subject.template
          )
            ? true
            : false
        },
        {
          key: ACTION.update,
          value: rules?.find(
            (item) => item.actions === ACTION.update && item.subject === subject.template
          )
            ? true
            : false
        },
        {
          key: ACTION.delete,
          value: rules?.find(
            (item) => item.actions === ACTION.delete && item.subject === subject.template
          )
            ? true
            : false
        }
      ]
    },
    {
      label: "Danh mục người dùng",
      subject: "user",
      actions: [
        {
          key: ACTION.create,
          value: rules?.find(
            (item) => item.actions === ACTION.create && item.subject === subject.user
          )
            ? true
            : false
        },
        {
          key: ACTION.update,
          value: rules?.find(
            (item) => item.actions === ACTION.update && item.subject === subject.user
          )
            ? true
            : false
        },
        {
          key: ACTION.delete,
          value: rules?.find(
            (item) => item.actions === ACTION.delete && item.subject === subject.user
          )
            ? true
            : false
        }
      ]
    }
  ];
};

function toJson(values = {}) {
  return {
    id: values.id || undefined,
    organization: values.organization || undefined,
    coverImage: values.coverImage || "",
    avatar: values.avatar || "",
    description: values.description || undefined,
    email: values.email || undefined,
    name: values.name || undefined,
    phone: values.phone || undefined,
    title: values.title || undefined,
    role: values.role || undefined,
    selectTemplate: values.selectTemplate || undefined,
    social: values.social || undefined,
    status: values.status || undefined,
    createdAt: values.createdAt || undefined,
    updatedAt: values.updatedAt || undefined,
    userType: values.userType || undefined,
    website: values.website || undefined
  };
}

function getFormValues(values) {
  return {
    id: values.id,
    email: values.email,
    password: values.password,
    role: values.role, //loại tài khoản
    organization: values.organization,
    level: values.level,
    rules: values.rules || [],
    active: values.active,
    address: values.address,
    avatar: values.avatar,
    description: values.description,
    name: values.name,
    phone: values.phone,
    title: values.title,
    social: values.social || [],
    selectTemplate: values.selectTemplate,
    userType: values.userType,
    company: values.company || undefined,
    video: values.video || undefined,
    blog: values.blog || undefined,
    exchangeContactStatus: values.exchangeContactStatus || undefined,
    leadFormConfig: values.leadFormConfig || undefined
  };
}

function setProfileFormValues(values) {
  return {
    id: values.id,
    cardId: values.cardId,
    email: values.email || "",
    address: values.address || "",
    avatar: values.avatar || "",
    description: values.description || "",
    name: values.name || "",
    phone: values.phone || "",
    title: values.title || "",
    social: values.social || [],
    selectTemplate: values.selectTemplate || "",
    organization: values.organization || undefined,
    organizationInfo: values.organizationInfo || undefined,
    avatarFileName: values.avatarFileName || "",
    userType: values.userType
  };
}

function setSettingFormValues(values) {
  return {
    id: values.id || undefined,
    email: values.email || undefined,
    password: values.password || undefined,
    role: values.role || undefined, //loại tài khoản
    organization: values.organization || undefined,
    level: values.level || undefined,
    active: values.active,
    rules: []
  };
}

export const USER_TYPE = {
  organization: "organization",
  personal: "personal",
  collaborator: "collaborator"
};

export default {
  toJson,
  getFormValues,
  setProfileFormValues,
  setSettingFormValues
};
