import camelCase from "lodash/camelCase";

const modules = {};
const requireModule = require.context(
  // Search for files in the current directory.
  ".",
  // Search for files in subdirectories.
  true,
  // Include any .js files that are not this file or a unit test.
  /^((?!index|actions|getters|mutations|state|\.unit\.).)*\.js$/
);
requireModule.keys().forEach((fileName) => {
  const moduleDefinition = requireModule(fileName);
  const module = camelCase(
    fileName
      .replace(/^\.\//, "")
      .replace(/\.\w+$/, "")
      .split(/\//)[0]
  );

  modules[module] = moduleDefinition.default;
});

export default modules;
