import RoleModel from "@/core/domain/models/role-model";

export default {
  getAccountRole: (state) => {
    return state.loginAccount.role;
  },
  getLoginAccount: (state) => {
    return state.loginAccount;
  },
  isSuperAdmin: (state) => {
    return RoleModel.isSuperAdmin(state.loginAccount.role);
  },
  isAdmin: (state) => {
    return RoleModel.isAdmin(state.loginAccount.role);
  },
  isUser: (state) => {
    return RoleModel.isUser(state.loginAccount.role);
  }
};
