<template>
  <div class="phone-container base-horizontal-padding">
    <div class="phone">
      <div class="notch-container">
        <div class="notch"></div>
      </div>
      <slot />
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({});
</script>
