export default {
  total: 0,
  pageSize: 10,
  data: [],
  isFetching: false,
  loading: false,
  isSuccess: false,
  detail: {},
  error: "",
  columns: [
    {
      title: "STT",
      dataIndex: "key",
      key: "key",
      align: "center"
    },
    {
      title: "ID",
      key: "id",
      dataIndex: "id",
      width: 120
    },
    {
      title: "Avatar",
      key: "owner.avatar",
      dataIndex: "owner"
    },
    {
      title: "Họ tên",
      key: "owner.name",
      dataIndex: "name",
      ellipsis: true
    },
    {
      title: "Email",
      key: "owner.email",
      dataIndex: "owner"
    },
    {
      title: "Chức danh",
      key: "owner.title",
      dataIndex: "owner"
    },
    {
      title: "Lượt xem",
      key: "owner.viewCount",
      dataIndex: "owner",
      align: "right"
    },
    {
      title: "QR",
      key: "qrcode",
      align: "center"
    },
    {
      title: "Ngày kích hoạt",
      key: "activeDate",
      dataIndex: "activeDate"
    },
    {
      title: "Thao tác",
      key: "tableAction",
      width: 150,
      align: "center"
    }
  ]
};
