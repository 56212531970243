<template>
  <div class="logo-section">
    <img style="height: 46px" :src="logo" />
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  props: ["logo"]
});
</script>
