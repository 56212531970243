const project = process.env.VUE_APP_PROJECT;

//common
export const COLLAPSE_IC = "/assets/icons/collapse-ic.svg";
export const EXPAND_IC = "/assets/icons/expand-ic.svg";
export const PLUS_IC = "/assets/icons/plus-ic.svg";
export const CLOSE_IC = "/assets/icons/close-ic.svg";
export const ARROW_RIGHT_IC = "/assets/icons/arrow-right-ic.svg";

//notification
export const NOTIFICATION_IC = "/assets/icons/notification/bell-ic.svg";
export const NOTIFICATION_VIEW_IC = "/assets/icons/notification/view-notification-ic.svg";
export const NOTIFICATION_ECARD_IC = "/assets/icons/notification/ecard-ic.svg";

//dashboard
export const DASHBOARD_IC = {
  viewProfile: `/assets/icons/dashboard/${project}/view-profile-ic.svg`,
  saveProfile: `/assets/icons/dashboard/${project}/save-profile-ic.svg`,
  lead: `/assets/icons/dashboard/${project}/lead-ic.svg`,
  contact: `/assets/icons/dashboard/${project}/contact-ic.svg`
};
