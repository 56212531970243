const state = {
  innerWidth: window.innerWidth,
  innerHeight: window.innerHeight
};

const mutations = {
  RESIZE(state, data) {
    state.innerWidth = data.innerWidth;
    state.innerHeight = data.innerHeight;
  }
};

const actions = {
  resize({ commit }, data) {
    commit("RESIZE", data);
  }
};

const getters = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
