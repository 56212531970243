import store from "@/store";
import { getDefaultRoute, routes } from "./routes";
import { createRouter, createWebHistory } from "vue-router";
import { getLoginAccount } from "@/core/utils/auth";

const router = createRouter({
  history: createWebHistory(),
  routes: Object.values(routes)
});

router.beforeEach(async (to, from, next) => {
  if (to.name === routes.forgotPassword.name || to.name === routes.cardInfo.name)
    store.commit("account/SET_SUCCESS_STATUS", false);

  const authRequired = to.matched.some((route) => route.meta.authRequired);
  if (!authRequired) return next();

  const account = getLoginAccount();
  if (account && to.meta.roles.includes(account.role)) return next();

  return next(getDefaultRoute());
});

export default router;
