import { routes } from "@/router/routes";

export const MENU_ITEM = {
  // Super Admin
  dashboard: {
    key: routes.dashboard.name,
    title: "Dashboard",
    icon: "dashboard"
  },
  template: {
    key: routes.template.name,
    title: "Template",
    icon: "template"
  },
  organization: {
    key: routes.organization.name,
    title: "Tổ chức",
    icon: "organization"
  },
  user: {
    key: routes.card.name,
    title: "Danh bạ",
    icon: "user"
  },
  createCards: {
    key: routes.createCards.name,
    title: "Định danh thẻ",
    icon: "card"
  },
  report: {
    key: routes.report.name,
    title: "Báo cáo",
    icon: "report"
  },
  // Admin
  organizationEmployee: {
    key: routes.organizationEmployee.name,
    title: "Danh sách người dùng",
    icon: "organization"
  },
  organizationDetail: {
    key: routes.organizationDetail.name,
    title: "Thông tin tổ chức",
    icon: "user"
  },
  organizationReport: {
    key: routes.organizationReport.name,
    title: "Báo cáo",
    icon: "report"
  },
  organizationDashboard: {
    key: routes.organizationDashboard.name,
    title: "Dashboard",
    icon: "dashboard"
  },
  // User
  updateProfile: {
    key: routes.updateProfile.name,
    title: "Thông tin tài khoản",
    icon: "user"
  },
  userDashboard: {
    key: routes.userDashboard.name,
    title: "Dashboard",
    icon: "dashboard"
  },
  userReport: {
    key: routes.userReport.name,
    title: "Báo cáo",
    icon: "report"
  }
};

const menuByRole = {
  superAdmin: [
    MENU_ITEM.dashboard,
    MENU_ITEM.template,
    MENU_ITEM.organization,
    MENU_ITEM.user,
    MENU_ITEM.createCards,
    MENU_ITEM.report
  ],
  admin: [
    MENU_ITEM.organizationDashboard,
    MENU_ITEM.organizationEmployee,
    MENU_ITEM.organizationDetail,
    MENU_ITEM.organizationReport
  ],
  editor: [MENU_ITEM.organizationEmployee, MENU_ITEM.updateProfile],
  user: [MENU_ITEM.userDashboard, MENU_ITEM.userReport]
};

export default menuByRole;
