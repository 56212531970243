import cardModel from "@/core/domain/models/card-model";
import cardState from "./state";

export default {
  GET_CARD_FETCHING(state = cardState) {
    state.isFetching = true;
  },
  GET_CARD_ERROR(state = cardState, message) {
    state.isFetching = false;
    state.error = message;
  },
  GET_CARD_SUCCESS(state = cardState, { total, data, pageSize }) {
    state.isFetching = false;
    state.error = "";
    state.total = total;
    state.pageSize = pageSize;
    state.data = data;
  },
  SET_CARD_LOADING(state = cardState) {
    state.isSuccess = false;
    state.loading = true;
  },
  SET_NEW_CARDS(state = cardState, data) {
    state.newCards = data;
    state.loading = false;
    state.isSuccess = true;
  },
  CREATE_CARDS_ERROR(state = cardState, message) {
    state.newCards = [];
    state.error = message;
    state.loading = false;
  },
  UPDATE_CARD_SUCCESS(state = cardState, values) {
    const index = state.data.findIndex((item) => item.id === values.id);
    state.data.splice(index, 1, cardModel.tableCardJson(values));
    state.loading = false;
    state.isSuccess = true;
  },
  UPDATE_CARD_ERROR(state = cardState, message) {
    state.error = message;
    state.loading = false;
  },
  REMOVE_CARD_SUCCESS(state = cardState, values) {
    const index = state.data.findIndex((item) => item.id === values.id);
    state.data.splice(index, 1, cardModel.tableCardJson(values));
    state.loading = false;
    state.isSuccess = true;
  },
  REMOVE_CARD_ERROR(state = cardState, message) {
    state.error = message;
    state.loading = false;
    state.isSuccess = false;
  },
  GET_CARD_DETAIL_FETCHING(state = cardState) {
    state.isSuccess = false;
    state.isFetching = true;
    state.detail = {};
  },
  GET_CARD_DETAIL_SUCCESS(state = cardState, data) {
    state.detail = data;
    state.isSuccess = true;
    state.isFetching = false;
  },
  GET_CARD_DETAIL_ERROR(state = cardState, message) {
    state.error = message;
    state.isSuccess = false;
    state.isFetching = false;
    state.detail = {};
  }
};
