import { getFirebaseToken } from "@/core/domain/helpers/firebase-message";
import { getLoginAccount, getLoginToken, removeLoginAccount } from "@/core/utils/auth";
import router from "@/router";
import { routes } from "@/router/routes";
import axios from "axios";
import fileDownload from "js-file-download";
import apiCode from "./api-code";

class ApiResultModel {
  constructor(result = { status: 0, code: -1, message: "Hệ thống đang bận", data: {} }) {
    this.status = result.status;
    this.code = result.code;
    this.message = result.message;
    this.data = result.data;
  }

  toJson() {
    return {
      status: this.status,
      code: this.code,
      message: this.message,
      data: this.data
    };
  }
}

export default class ApiService {
  constructor(host, headers) {
    this.host = host;
    this.headers = headers;
  }

  #sessionTimeout = () => {
    axios.put(`${this.host}/user/${getLoginAccount().id}/logout`, {}, { headers: this.headers });
    removeLoginAccount();
    router.push(routes.login);
  };

  async get(path, query) {
    try {
      const headers = {
        ...this.headers,
        authorization: getLoginToken(),
        deviceInfo: JSON.stringify({ firebaseToken: await getFirebaseToken() })
      };
      const url = `${this.host}${path}`;
      const response = await axios.get(url, {
        params: query,
        headers
      });

      if (!response || !response.data) return new ApiResultModel().toJson();

      const result = new ApiResultModel(response.data).toJson();
      if (result.code === apiCode.sessionTimeout) {
        this.#sessionTimeout();
      }

      return result;
    } catch (error) {
      return new ApiResultModel().toJson();
    }
  }

  async post(path, body, query) {
    try {
      const headers = {
        ...this.headers,
        authorization: getLoginToken(),
        deviceInfo: JSON.stringify({ firebaseToken: await getFirebaseToken() })
      };
      const url = `${this.host}${path}`;
      const response = await axios.post(url, body, {
        headers,
        params: query
      });
      if (!response || !response.data) return new ApiResultModel().toJson();

      const result = new ApiResultModel(response.data).toJson();
      if (result.code === apiCode.sessionTimeout) {
        this.#sessionTimeout();
      }

      return result;
    } catch (error) {
      return new ApiResultModel().toJson();
    }
  }

  async put(path, body, query) {
    try {
      const headers = {
        ...this.headers,
        authorization: getLoginToken(),
        deviceInfo: JSON.stringify({ firebaseToken: await getFirebaseToken() })
      };
      const url = `${this.host}${path}`;
      const response = await axios.put(url, body, {
        headers,
        params: query
      });
      if (!response || !response.data) return new ApiResultModel().toJson();

      const result = new ApiResultModel(response.data).toJson();
      if (result.code === apiCode.sessionTimeout) {
        this.#sessionTimeout();
      }

      return result;
    } catch (error) {
      return new ApiResultModel().toJson();
    }
  }

  async remove(path) {
    try {
      const headers = {
        ...this.headers,
        authorization: getLoginToken(),
        deviceInfo: JSON.stringify({ firebaseToken: await getFirebaseToken() })
      };
      const url = `${this.host}${path}`;
      const response = await axios.delete(url, { headers });
      if (!response || !response.data) return new ApiResultModel().toJson();

      const result = new ApiResultModel(response.data).toJson();
      if (result.code === apiCode.sessionTimeout) {
        this.#sessionTimeout();
      }

      return result;
    } catch (error) {
      return new ApiResultModel().toJson();
    }
  }

  async save(path, method = "POST", fileName) {
    const url = `${this.host}${path}`;
    const headers = { ...this.headers, authorization: getLoginToken() };

    return await axios({
      url,
      method,
      responseType: "blob", // Important
      headers
    })
      .then((response) => {
        fileDownload(response.data, fileName);
        return { isSuccess: true };
      })
      .catch(() => {
        return { isSuccess: false };
      });
  }

  async upload(path, data) {
    try {
      const headers = { ...this.headers, authorization: getLoginToken() };
      const url = `${this.host}${path}`;
      const response = await axios.post(url, data, {
        headers
      });
      if (!response || !response.data) return new ApiResultModel().toJson();

      const result = new ApiResultModel(response.data).toJson();
      if (result.code === apiCode.sessionTimeout) {
        this.#sessionTimeout();
      }

      return result;
    } catch (error) {
      return new ApiResultModel().toJson();
    }
  }
}
