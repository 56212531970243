function tableValues(values) {
  return {
    id: values.id || undefined,
    name: values.name || undefined,
    thumbnail: values.thumbnail || undefined,
    category: values.category || undefined,
    active: values.active || undefined,
    order: values.order || undefined,
    createdAt: values.createdAt || undefined
  };
}

function getFormValues(values) {
  return {
    id: values.id || undefined,
    name: values.name || undefined,
    thumbnail: values.thumbnail || undefined,
    active: values.active,
    category: values.category || undefined,
    order: values.order,
    isPremium: values.isPremium
  };
}

export default {
  getFormValues,
  tableValues
};
