export default {
  env: "development",
  home: "https://ecardvip.com",
  host: "https://hidev.ecardvip.com",
  serverHost: "https://iddev.ecardvip.com",
  profile: {
    host: "https://iddev.ecardvip.com",
    url: "https://iddev.ecardvip.com/p/:userId"
  },
  api: {
    host: "https://hidevapi.ecardvip.com",
    imageHost: "//hidevapi.ecardvip.com/user/upload/avatar",
    importEmployee: "//hidevapi.ecardvip.com/organization/upload/employee",
    product: "admin",
    token: "2b5fa55f4518e8f3cf810fce6591d347a69027d7916cdd54b6665f06b1a3ffe9"
  },
  color: {
    primary: "#BC254A",
    secondary: "#5c738d",
    text: "#353535",
    text2: "#222222",
    border: "#B1B1B1"
  }
};
