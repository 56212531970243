import { ORGANIZATION_TYPE } from "./organization-model";
import { USER_TYPE } from "./user-model";

export const getCompanyState = (userType, orgInfo, company) => {
  if (userType === USER_TYPE.personal || orgInfo?.type === ORGANIZATION_TYPE.dynamic) {
    return company;
  }

  return orgInfo;
};
