<template>
  <div class="save-button-container">
    <a-button type="primary" html-type="submit" :loading="loading" :disabled="disabled" @click="$emit('save')">
      <template #icon>
        <img src="/assets/icons/check-ic.svg" />
      </template>
      <span class="btn-text">
        <slot />
      </span>
    </a-button>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  props: {
    loading: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    title: { type: String, default: "" },
  },
  setup() {
    return { t: useI18n().t };
  }
});
</script>
