import getEmployeeListUseCase from "@/core/domain/usecases/card/get-card-list";
import removeCardOwnerUseCase from "@/core/domain/usecases/card/remove-card-owner";
import createEmployeeUseCase from "@/core/domain/usecases/user/create-user";
import getEmployeeDetailUseCase from "@/core/domain/usecases/user/get-user-detail";
import updateEmployeeUseCase from "@/core/domain/usecases/user/update-user";
import { ALERT_ERROR, ALERT_SUCCESS } from "../../actions/alert";

export default {
  async getEmployeeList({ commit, dispatch }, query) {
    commit("GET_EMPLOYEE_FETCHING");
    const result = await getEmployeeListUseCase(query);
    if (result.isSuccess)
      return commit("GET_EMPLOYEE_SUCCESS", {
        total: result.data.total,
        pageSize: result.data.limit,
        data: result.data.items
      });

    dispatch(ALERT_ERROR, result.message, { root: true });
    return commit("GET_EMPLOYEE_ERROR", result.message);
  },
  async getEmployeeDetail({ commit, dispatch }, id) {
    if (!id) return commit("GET_EMPLOYEE_DETAIL_SUCCESS", {});

    commit("GET_EMPLOYEE_DETAIL_FETCHING");
    const result = await getEmployeeDetailUseCase(id);
    if (result.isSuccess) {
      return commit("GET_EMPLOYEE_DETAIL_SUCCESS", result.data);
    }

    dispatch(ALERT_ERROR, result.message, { root: true });
    return commit("GET_EMPLOYEE_DETAIL_ERROR", result.message);
  },
  async removeEmployee({ commit, dispatch }, { cardId }) {
    commit("SET_EMPLOYEE_LOADING");
    const result = await removeCardOwnerUseCase(cardId);
    if (result.isSuccess) return commit("UPDATE_EMPLOYEE_SUCCESS", result.data);

    dispatch(ALERT_ERROR, result.message, { root: true });
    return commit("UPDATE_EMPLOYEE_ERROR", result.message);
  },
  async updateEmployee({ commit, dispatch }, data) {
    commit("SET_EMPLOYEE_LOADING");
    const result = await updateEmployeeUseCase(data);
    if (result.isSuccess) {
      dispatch(ALERT_SUCCESS, result.message, { root: true });
      return commit("UPDATE_EMPLOYEE_SUCCESS", result.data);
    }

    dispatch(ALERT_ERROR, result.message, { root: true });
    return commit("UPDATE_EMPLOYEE_ERROR", result.message);
  },
  async createEmployee({ commit, dispatch }, data) {
    commit("SET_EMPLOYEE_LOADING");
    const result = await createEmployeeUseCase(data);
    if (result.isSuccess) {
      dispatch(ALERT_SUCCESS, result.message, { root: true });
      return commit("CREATE_EMPLOYEE_SUCCESS", result.data);
    }

    dispatch(ALERT_ERROR, result.message, { root: true });
    return commit("CREATE_EMPLOYEE_ERROR", result.message);
  }
};
