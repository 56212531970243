import CardApi from "./card-api";
import CrawApi from "./craw-api";
import DashboardApi from "./dashboard-api";
import ExchangeContactApi from "./exchange-contact-api";
import LeadFormApi from "./lead-form";
import NotificationApi from "./notification-api";
import OrganizationApi from "./organization-api";
import ProfileApi from "./profile-api";
import TemplateApi from "./template-api";
import TrackingApi from "./tracking-api";
import UserApi from "./user-api";

export const organizationApi = new OrganizationApi();
export const profileApi = new ProfileApi();
export const userApi = new UserApi();
export const templateApi = new TemplateApi();
export const cardApi = new CardApi();
export const trackingApi = new TrackingApi();
export const dashboardApi = new DashboardApi();
export const crawApi = new CrawApi();
export const exchangeContactApi = new ExchangeContactApi();
export const leadFormApi = new LeadFormApi();
export const notificationApi = new NotificationApi();
