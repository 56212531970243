export default class RoleModel {
  constructor(name) {
    this.name = name;
  }

  toJson() {
    return {
      name: this.name
    };
  }

  static ROLE = {
    superAdmin: "superAdmin",
    admin: "admin",
    user: "user",
    editor: "editor"
  };

  static isSuperAdmin = (_role) => _role && _role === this.ROLE.superAdmin;
  static isAdmin = (_role) => _role && _role === this.ROLE.admin;
  static isUser = (_role) => !_role || _role === this.ROLE.user;
  static isEditor = (_role) => !_role || _role === this.ROLE.editor;

  static roleText = (role) => {
    if (role === this.ROLE.admin) return "Admin";
    if (role === this.ROLE.user) return "Member";

    return "";
  };

  static ROLE_LIST = [
    { value: this.ROLE.admin, label: "Admin" },
    { value: this.ROLE.editor, label: "Editor" },
    { value: this.ROLE.user, label: "Member" }
  ];
}
