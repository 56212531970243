<script>
import PhoneDisplay from "@/components/layout/template/PhoneDisplay.vue";
import { computed, h, watch } from "vue";

export default {
  name: "ReviewOrganizationTemplate",
  props: ["organizationInfo", "selectTemplate", "orgId"],
  render(props) {
    const selectTemplate = computed(() => props.selectTemplate);
    watch([selectTemplate, props.organizationInfo], (nextState) => {
      const data = { ...nextState[1], selectTemplate: nextState[0] };
      document
        .getElementById("iframe-organization")
        .contentWindow.postMessage(JSON.stringify({ organizationInfo: data }), "*");
    });

    return h(PhoneDisplay, () => {
      return h("iframe", {
        id: "iframe-organization",
        src: props.orgId ? `/organization/template/${props.orgId}` : "/organization/template/0"
      });
    }
    );

  }
};
</script>
