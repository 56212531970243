import createLeadFormUseCase from "@/core/domain/usecases/lead-form/create-lead-form";
import getLeadFormListUseCase from "@/core/domain/usecases/lead-form/get-lead-form-list";
import removeLeadFormUseCase from "@/core/domain/usecases/lead-form/remove-lead-form";
import { ALERT_ERROR, ALERT_SUCCESS } from "../../actions/alert";

export default {
  async getLeadFormList({ commit, dispatch }, query) {
    commit("GET_LEAD_FORM_FETCHING");
    const result = await getLeadFormListUseCase(query);
    if (result.isSuccess)
      return commit("GET_LEAD_FORM_SUCCESS", {
        total: result.data.total,
        pageSize: result.data.limit,
        data: result.data.items,
        columns: result.data.columns
      });

    dispatch(ALERT_ERROR, result.message, { root: true });
    return commit("GET_LEAD_FORM_ERROR", result.message);
  },

  async removeLeadForm({ commit, dispatch }, id) {
    commit("SET_LEAD_FORM_LOADING");
    const result = await removeLeadFormUseCase(id);
    if (result.isSuccess) {
      dispatch(ALERT_SUCCESS, result.message, { root: true });
      return commit("REMOVE_LEAD_FORM_SUCCESS", id);
    }

    dispatch(ALERT_ERROR, result.message, { root: true });
    return commit("REMOVE_LEAD_FORM_ERROR", result.message);
  },

  async createLeadForm({ commit, dispatch }, data) {
    commit("SET_LEAD_FORM_LOADING");
    const result = await createLeadFormUseCase(data);
    if (result.isSuccess) {
      dispatch(ALERT_SUCCESS, result.message, { root: true });
      return commit("CREATE_LEAD_FORM_SUCCESS", result.data);
    }

    dispatch(ALERT_ERROR, result.message, { root: true });
    return commit("CREATE_LEAD_FORM_ERROR", result.message);
  }
};
