import { API_HOST, API_HEADERS } from "@/config";
import apiCode from "../services/api-services/api-code";
import ApiService from "../services/api-services/api-service";

export default class TemplateApi {
  #apiService = new ApiService(API_HOST, API_HEADERS);

  async getTemplateListPagination(query) {
    const result = await this.#apiService.get("/template/pagination", query);

    return {
      isSuccess: result.code === apiCode.success,
      status: result.status,
      code: result.code,
      message: result.message,
      data: {
        items: result.data.items || [],
        total: Number(result.data.total) || 0,
        limit: Number(result.data.limit)
      }
    };
  }

  async getTemplateList(query) {
    const result = await this.#apiService.get("/template", query);

    return {
      isSuccess: result.code === apiCode.success,
      status: result.status,
      code: result.code,
      message: result.message,
      data: {
        items: result.data.items || []
      }
    };
  }

  async createTemplate(body) {
    const result = await this.#apiService.post("/template", body);
    return {
      isSuccess: result.code === apiCode.success,
      status: result.status,
      code: result.code,
      message: result.message,
      data: result.data
    };
  }

  async updateTemplate(id, body) {
    const result = await this.#apiService.put(`/template/${id}`, body);
    return {
      isSuccess: result.code === apiCode.success,
      status: result.status,
      code: result.code,
      message: result.message,
      data: result.data
    };
  }

  async removeTemplate(id) {
    const result = await this.#apiService.remove(`/template/${id}`);
    return {
      ...{ isSuccess: result.code === apiCode.success },
      ...result
    };
  }
}
