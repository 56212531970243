import createExchangeContactUseCase from "@/core/domain/usecases/exchange-contact/create-exchange-contact";
import getExchangeContactListUseCase from "@/core/domain/usecases/exchange-contact/get-exchange-contact-list";
import removeExchangeContactUseCase from "@/core/domain/usecases/exchange-contact/remove-exchange-contact";
import saveExchangeContactUseCase from "@/core/domain/usecases/exchange-contact/save-exchange-contact";
import { ALERT_ERROR, ALERT_SUCCESS } from "../../actions/alert";

export default {
  async getExchangeContactList({ commit, dispatch }, query) {
    commit("GET_EXCHANGE_CONTACT_FETCHING");
    const result = await getExchangeContactListUseCase(query);
    if (result.isSuccess)
      return commit("GET_EXCHANGE_CONTACT_SUCCESS", {
        total: result.data.total,
        pageSize: result.data.limit,
        data: result.data.items
      });

    dispatch(ALERT_ERROR, result.message, { root: true });
    return commit("GET_EXCHANGE_CONTACT_ERROR", result.message);
  },
  async saveExchangeContact(actions, id) {
    await saveExchangeContactUseCase(id);
  },
  async removeExchangeContact({ commit, dispatch }, id) {
    commit("SET_EXCHANGE_CONTACT_LOADING");
    const result = await removeExchangeContactUseCase(id);
    if (result.isSuccess) {
      dispatch(ALERT_SUCCESS, result.message, { root: true });
      return commit("REMOVE_EXCHANGE_CONTACT_SUCCESS", id);
    }

    dispatch(ALERT_ERROR, result.message, { root: true });
    return commit("REMOVE_EXCHANGE_CONTACT_ERROR", result.message);
  },
  async createExchangeContact({ commit, dispatch }, data) {
    commit("SET_EXCHANGE_CONTACT_LOADING");
    const result = await createExchangeContactUseCase(data);
    if (result.isSuccess) {
      dispatch(ALERT_SUCCESS, result.message, { root: true });
      return commit("CREATE_EXCHANGE_CONTACT_SUCCESS", result.data);
    }

    dispatch(ALERT_ERROR, result.message, { root: true });
    return commit("CREATE_EXCHANGE_CONTACT_ERROR", result.message);
  }
};
