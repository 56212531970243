import { API_HEADERS, API_HOST } from "@/config";
import apiCode from "../services/api-services/api-code";
import ApiService from "../services/api-services/api-service";

export default class {
  #apiService = new ApiService(API_HOST, API_HEADERS);

  async saveExchangeContact(id) {
    window.location.href = `${API_HOST}/exchange-contact/${id}/save?token=${API_HEADERS.token}&product=${API_HEADERS.product}`;

    return {
      isSuccess: true
    };
  }

  async getExchangeContactListPagination(query) {
    const result = await this.#apiService.get("/exchange-contact/pagination", query);
    return {
      isSuccess: result.code === apiCode.success,
      status: result.status,
      code: result.code,
      message: result.message,
      data: {
        items: result.data.items || [],
        total: Number(result.data.total) || 0,
        limit: Number(result.data.limit) || 10
      }
    };
  }

  async createExchangeContact(body) {
    const result = await this.#apiService.post("/exchange-contact", body);
    return {
      isSuccess: result.code === apiCode.success,
      status: result.status,
      code: result.code,
      message: result.message,
      data: result.data
    };
  }

  async removeExchangeContact(id) {
    const result = await this.#apiService.remove(`/exchange-contact/${id}`);
    return {
      ...{ isSuccess: result.code === apiCode.success },
      ...result
    };
  }
}
