import { API_HEADERS, API_HOST } from "@/config";
import apiCode from "../services/api-services/api-code";
import ApiService from "../services/api-services/api-service";

export default class UploadApi {
  #apiService = new ApiService(API_HOST, API_HEADERS);

  async createTracking(trackingId, body) {
    const result = await this.#apiService.post(`/tracking/${trackingId}`, body);

    return {
      isSuccess: result.code === apiCode.success,
      status: result.status,
      code: result.code,
      message: result.message,
      data: result.data
    };
  }
}
