import { CARD_STATUS } from "@/core/domain/models/card-model";

export default {
  total: 0,
  pageSize: 10,
  data: [],
  newCards: [],
  isFetching: false,
  loading: false,
  isSuccess: false,
  detail: {},
  error: "",
  status: [
    { label: "Hoạt động", value: CARD_STATUS.on },
    { label: "Ngừng hoạt động", value: CARD_STATUS.off },
    { label: "Chưa kích hoạt", value: CARD_STATUS.inactive }
  ],
  columns: [
    {
      title: "STT",
      dataIndex: "key",
      key: "key",
      align: "center"
      // width: 60
    },
    {
      title: "ID",
      key: "id",
      dataIndex: "id"
    },
    {
      title: "Họ tên",
      key: "owner.name",
      dataIndex: "owner"
    },
    {
      title: "Email",
      key: "owner.account",
      dataIndex: "owner"
    },
    {
      title: "Account",
      key: "owner.account",
      dataIndex: "owner"
    },
    {
      title: "SĐT",
      key: "owner.phone",
      dataIndex: "owner"
    },
    {
      title: "Vai trò",
      key: "owner.role",
      dataIndex: "owner"
    },
    {
      title: "QR",
      key: "qrcode",
      align: "center"
    },
    {
      title: "Lượt lưu",
      key: "owner.saveCount",
      dataIndex: "owner",
      align: "right"
    },
    {
      title: "Lượt xem",
      key: "owner.viewCount",
      dataIndex: "owner",
      align: "right"
    },
    {
      title: "Tên tổ chức",
      key: "organizationName",
      dataIndex: "organizationName"
    },
    {
      title: "Ngày tạo",
      key: "createdAt",
      dataIndex: "createdAt"
    },
    {
      title: "Ngày kích hoạt",
      key: "activeDate",
      dataIndex: "activeDate"
    },
    {
      title: "Trạng thái",
      key: "cardStatus",
      dataIndex: "cardStatus"
    },
    {
      title: "Thao tác",
      key: "tableAction",
      align: "center",
      fixed: "right"
    }
  ]
};
