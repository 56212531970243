import modules from "@/core/presentation/modules";
import { createLogger, createStore } from "vuex";

const debug = process.env.NODE_ENV !== "production";

export default createStore({
  state() {
    return {
      loading: false
    };
  },
  mutations: {
    SET_LOADING(state, loading) {
      state.loading = loading;
    }
  },
  modules,
  strict: process.env.NODE_ENV !== "production",
  plugins: debug ? [createLogger()] : []
});
