<template>
  <div>
    <router-link :to="props.route" class="go-back" @click="emit('back')">
      <a-space align="center">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8.71514 13.3346L3.48438 8.0013L8.71514 2.66797" stroke="currentColor" stroke-width="1.5"
            stroke-linecap="round" stroke-linejoin="round" />
        </svg>
        <div>{{ t("action.back") }}</div>
      </a-space>
    </router-link>
  </div>
</template>

<script setup>
import { useI18n } from "vue-i18n";


const t = useI18n().t;
const props = defineProps(["route"]);
const emit = defineEmits(["back"]);
</script>
