import userModel from "@/core/domain/models/user-model";
import userState from "./state";

export default {
  GET_USER_FETCHING(state = userState) {
    state.isFetching = true;
    state.data = [];
  },
  GET_USER_ERROR(state = userState, message) {
    state.isFetching = false;
    state.error = message;
  },
  GET_USER_SUCCESS(state = userState, { total, data, pageSize }) {
    state.isFetching = false;
    state.error = "";
    state.total = total;
    state.pageSize = pageSize;
    state.data = data;
  },
  SET_USER_LOADING(state = userState) {
    state.isSuccess = false;
    state.loading = true;
    state.error = "";
  },
  CREATE_USER_SUCCESS(state = userState, values) {
    state.data.unshift(userModel.toJson(values));
    state.isSuccess = true;
    state.loading = false;
  },
  CREATE_USER_ERROR(state = userState, message) {
    state.error = message;
    state.isSuccess = false;
    state.loading = false;
  },
  UPDATE_USER_SUCCESS(state = userState, values) {
    const index = state.data.findIndex((item) => item.id === values.id);
    state.data.splice(index, 1, userModel.toJson(values));
    state.isSuccess = true;
    state.loading = false;
  },
  UPDATE_USER_ERROR(state = userState, message) {
    state.error = message;
    state.isSuccess = false;
    state.loading = false;
  },
  REMOVE_USER_SUCCESS(state = userState, id) {
    state.data = state.data.filter((item) => item.id !== id);
    state.isSuccess = true;
    state.loading = false;
  },
  REMOVE_USER_ERROR(state = userState, message) {
    state.error = message;
    state.isSuccess = false;
    state.loading = false;
  },
  GET_USER_DETAIL_FETCHING(state = userState) {
    state.isSuccess = false;
    state.isFetching = true;
    state.detail = {};
  },
  GET_USER_DETAIL_SUCCESS(state = userState, data) {
    state.detail = data;
    state.isFetching = false;
  },
  GET_USER_DETAIL_ERROR(state = userState, message) {
    state.error = message;
    state.isSuccess = false;
    state.isFetching = false;
    state.detail = {};
  },
  SEND_USER_EMAIL(state = userState, status) {
    state.isSuccess = status;
    state.loading = false;
  },
  UPLOAD_AVATAR_SUCCESS(state = userState, data) {
    state.uploadAvatar.id = data?.id;
    state.uploadAvatar.url = data?.url;
    state.uploadAvatar.isSuccess = true;
  },
  UPLOAD_AVATAR_ERROR(state = userState) {
    state.uploadAvatar.id = "";
    state.uploadAvatar.url = "";
    state.uploadAvatar.isSuccess = false;
  }
};
