import dashboardState from "./state";

export default {
  GET_DASHBOARD_FETCHING(state = dashboardState) {
    state.isFetching = true;
    state.error = "";
  },
  GET_DASHBOARD_ERROR(state = dashboardState, message) {
    state.isFetching = false;
    state.error = message;
  },
  GET_DASHBOARD_SUCCESS(state = dashboardState, data) {
    state.isFetching = false;
    state.error = "";

    state.totalUserCount = data?.totalUserCount || "0";
    state.trackingResumeCount.viewProfile = data?.trackingResumeCount.viewProfile || "";
    state.trackingResumeCount.saveProfile = data?.trackingResumeCount.saveProfile || "";
    state.trackingResumeCount.lead = data?.trackingResumeCount.lead || "";
    state.trackingResumeCount.exchangeContact = data?.trackingResumeCount.exchangeContact || "";

    state.socialLink = data?.socialLink || [];
    state.viewDevice.labels = data.viewDevice?.map((item) => item.label) || [];
    state.viewDevice.data = data.viewDevice?.map((item) => item.count) || [];
    state.viewSocial.labels = data.viewSocial?.map((item) => item.label) || [];
    state.viewSocial.data = data.viewSocial?.map((item) => item.count) || [];
    state.resumeChart.labels = data.resumeChart.labels || [];
    state.resumeChart.datasets.viewProfile = data.resumeChart.datasets?.viewProfile || [];
    state.resumeChart.datasets.saveProfile = data.resumeChart.datasets?.saveProfile || [];
    state.resumeChart.datasets.lead = data.resumeChart.datasets?.lead || [];
    state.resumeChart.datasets.exchangeContact = data.resumeChart.datasets?.exchangeContact || [];
  }
};
