import createOrganizationUseCase from "@/core/domain/usecases/organization/create-organization";
import getActiveOrganizationUseCase from "@/core/domain/usecases/organization/get-active-organization";
import getOrganizationDetailUseCase from "@/core/domain/usecases/organization/get-organization-detail";
import getOrganizationListUseCase from "@/core/domain/usecases/organization/get-organization-list";
import removeOrganizationUseCase from "@/core/domain/usecases/organization/remove-organization";
import updateOrganizationUseCase from "@/core/domain/usecases/organization/update-organization";
import { ALERT_ERROR, ALERT_SUCCESS } from "../../actions/alert";

export default {
  async getActiveOrganization({ commit }) {
    const result = await getActiveOrganizationUseCase();
    commit("GET_ACTIVE_ORGANIZATION", { data: result.data.items || [] });
  },
  async getOrganizationList({ commit, dispatch }, query) {
    commit("GET_ORGANIZATION_FETCHING");
    const result = await getOrganizationListUseCase(query);
    if (result.isSuccess)
      return commit("GET_ORGANIZATION_SUCCESS", {
        total: result.data.total,
        pageSize: result.data.limit,
        data: result.data.items
      });

    dispatch(ALERT_ERROR, result.message, { root: true });
    return commit("GET_ORGANIZATION_ERROR", result.message);
  },
  async getOrganizationDetail({ commit, dispatch }, id) {
    //id === 0 by pass iframe
    if (!id || id === "0") return commit("GET_ORGANIZATION_DETAIL_SUCCESS", {});

    commit("GET_ORGANIZATION_DETAIL_FETCHING");
    const result = await getOrganizationDetailUseCase(id);
    if (result.isSuccess) {
      return commit("GET_ORGANIZATION_DETAIL_SUCCESS", result.data);
    }

    dispatch(ALERT_ERROR, result.message, { root: true });
    return commit("GET_ORGANIZATION_DETAIL_ERROR", result.message);
  },
  async removeOrganization({ commit, dispatch }, id) {
    commit("SET_ORGANIZATION_LOADING");
    const result = await removeOrganizationUseCase(id);
    if (result.isSuccess) {
      dispatch(ALERT_SUCCESS, result.message, { root: true });
      return commit("REMOVE_ORGANIZATION_SUCCESS", id);
    }

    dispatch(ALERT_ERROR, result.message, { root: true });
    return commit("REMOVE_ORGANIZATION_ERROR", result.message);
  },
  async updateOrganization({ commit, dispatch }, data) {
    commit("SET_ORGANIZATION_LOADING");
    const result = await updateOrganizationUseCase(data);
    if (result.isSuccess) {
      dispatch(ALERT_SUCCESS, result.message, { root: true });
      return commit("UPDATE_ORGANIZATION_SUCCESS", result.data);
    }

    dispatch(ALERT_ERROR, result.message, { root: true });
    return commit("UPDATE_ORGANIZATION_ERROR", result.message);
  },
  async createOrganization({ commit, dispatch }, data) {
    commit("SET_ORGANIZATION_LOADING");
    const result = await createOrganizationUseCase(data);
    if (result.isSuccess) {
      dispatch(ALERT_SUCCESS, result.message, { root: true });
      return commit("CREATE_ORGANIZATION_SUCCESS", result.data);
    }

    dispatch(ALERT_ERROR, result.message, { root: true });
    return commit("CREATE_ORGANIZATION_ERROR", result.message);
  }
};
