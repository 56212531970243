import RoleModel from "@/core/domain/models/role-model";
import { getLoginAccount } from "@/core/utils/auth";

export const routesName = {
  notFound: "notFound",
  root: "root",
  contactUs: "contactUs",
  profile: "profile",
  organizationTemplate: "organizationTemplate",
  cardInfo: "cardInfo",
  admin: {
    root: "adminRoot",
    resetPassword: "resetPassword",
    forgotPassword: "forgotPassword",
    login: "login",
    template: "template",
    dashboard: "dashboard",
    report: "report",

    userDashboard: "userDashboard",
    userReport: "userReport",

    organizationDashboard: "organizationDashboard",
    organizationReport: "organizationReport",

    organization: "organization",
    user: "user",
    card: "card",
    updateProfile: "updateProfile",
    organizationDetail: "organizationDetail",
    organizationEmployee: "organizationEmployee",
    createOrganization: "createOrganization",
    updateOrganization: "updateOrganization",
    createEmployee: "createEmployee",
    updateEmployee: "updateEmployee",
    createUser: "createUser",
    updateUser: "updateUser",
    createCards: "createCards",
    notification: "notification"
  }
};

export function getDefaultRoute() {
  const account = getLoginAccount();
  if (!account) return { name: routesName.admin.login };

  if (account.role === RoleModel.ROLE.superAdmin) return { name: routesName.admin.dashboard };
  if (account.role === RoleModel.ROLE.admin || account.role === RoleModel.ROLE.editor)
    return {
      name: routesName.admin.organizationDashboard,
      params: { orgId: account.organization }
    };
  if (account.role === RoleModel.ROLE.user)
    return { name: routesName.admin.userDashboard, params: { userId: account.id } };

  return { name: routesName.admin.updateProfile, params: { userId: account.id } };
}

export const routes = {
  root: {
    path: "/",
    name: routesName.root,
    meta: { authRequired: false },
    component: () => import("../views/Home.vue")
  },
  cardInfo: {
    path: "/card/:cardId",
    name: routesName.cardInfo,
    meta: { authRequired: false },
    component: () => import("../views/CardInfo.vue")
  },
  contactUs: {
    path: "/contact-us",
    name: routesName.contactUs,
    meta: { authRequired: false },
    component: () => import("../views/ContactUs.vue")
  },
  profile: {
    path: "/p/:userId",
    name: routesName.profile,
    meta: { authRequired: false },
    component: () => import("../views/Profile.vue")
  },
  organizationTemplate: {
    path: "/organization/template/:orgId",
    name: routesName.organizationTemplate,
    meta: { authRequired: false },
    component: () => import("../views/OrganizationTemplate.vue")
  },
  forgotPassword: {
    path: "/admin/forgot-password",
    name: routesName.admin.forgotPassword,
    meta: { authRequired: false },
    component: () => import("../views/ForgotPassword.vue")
  },
  resetPassword: {
    path: "/admin/reset-password",
    name: routesName.admin.resetPassword,
    meta: { authRequired: false },
    component: () => import("../views/ResetPassword.vue")
  },
  login: {
    path: "/admin/login",
    name: routesName.admin.login,
    meta: { authRequired: false },
    component: () => import("../views/Login.vue")
  },
  notification: {
    path: "/admin/notification",
    name: routesName.admin.notification,
    meta: {
      authRequired: true,
      roles: [RoleModel.ROLE.superAdmin, RoleModel.ROLE.admin, RoleModel.ROLE.user]
    },
    component: () => import("../views/AccountNotification.vue")
  },
  adminRoot: {
    path: "/admin",
    name: routesName.admin.root,
    meta: {
      authRequired: true,
      roles: [RoleModel.ROLE.superAdmin, RoleModel.ROLE.admin, RoleModel.ROLE.user]
    },
    redirect: getDefaultRoute()
  },

  // Admin & Editor role
  organizationEmployee: {
    path: "/admin/organization/:orgId/employee",
    name: routesName.admin.organizationEmployee,
    meta: {
      authRequired: true,
      roles: [RoleModel.ROLE.superAdmin, RoleModel.ROLE.admin, RoleModel.ROLE.editor]
    },
    component: () => import("../views/admin/OrganizationEmployee.vue")
  },
  // Admin role
  organizationDetail: {
    path: "/admin/organization/:orgId",
    name: routesName.admin.organizationDetail,
    meta: { authRequired: true, roles: [RoleModel.ROLE.superAdmin, RoleModel.ROLE.admin] },
    component: () => import("../views/admin/OrganizationDetail.vue")
  },
  createEmployee: {
    path: "/admin/organization/:orgId",
    name: routesName.admin.createEmployee,
    meta: {
      authRequired: true,
      roles: [RoleModel.ROLE.superAdmin, RoleModel.ROLE.admin, RoleModel.ROLE.editor]
    },
    component: () => import("../views/admin/EmployeeInfo.vue")
  },
  organizationDashboard: {
    path: "/admin/dashboard/organization/:orgId",
    name: routesName.admin.organizationDashboard,
    meta: {
      authRequired: true,
      roles: [RoleModel.ROLE.superAdmin, RoleModel.ROLE.admin, RoleModel.ROLE.editor]
    },
    component: () => import("../views/admin/Dashboard.vue")
  },
  updateEmployee: {
    path: "/admin/organization/:orgId/update/:userId",
    name: routesName.admin.updateEmployee,
    meta: {
      authRequired: true,
      roles: [RoleModel.ROLE.superAdmin, RoleModel.ROLE.admin, RoleModel.ROLE.editor]
    },
    component: () => import("../views/admin/EmployeeInfo.vue")
  },
  organizationReport: {
    path: "/admin/report/organization/:orgId",
    name: routesName.admin.organizationReport,
    meta: {
      authRequired: true,
      roles: [RoleModel.ROLE.superAdmin, RoleModel.ROLE.admin, RoleModel.ROLE.editor]
    },
    component: () => import("../views/admin/Report.vue")
  },
  // Superadmin Role
  dashboard: {
    path: "/admin/dashboard",
    name: routesName.admin.dashboard,
    meta: { authRequired: true, roles: [RoleModel.ROLE.superAdmin] },
    component: () => import("../views/superadmin/Dashboard.vue")
  },
  template: {
    path: "/admin/template",
    name: routesName.admin.template,
    meta: { authRequired: true, roles: [RoleModel.ROLE.superAdmin] },
    component: () => import("../views/superadmin/Template.vue")
  },
  organization: {
    path: "/admin/organization",
    name: routesName.admin.organization,
    meta: { authRequired: true, roles: [RoleModel.ROLE.superAdmin] },
    component: () => import("../views/superadmin/Organization.vue")
  },
  createOrganization: {
    path: "/admin/organization/create",
    name: routesName.admin.createOrganization,
    meta: { authRequired: true, roles: [RoleModel.ROLE.superAdmin] },
    component: () => import("../views/superadmin/OrganizationInfo.vue")
  },
  updateOrganization: {
    path: "/admin/organization/update/:orgId",
    name: routesName.admin.updateOrganization,
    meta: { authRequired: true, roles: [RoleModel.ROLE.superAdmin] },
    component: () => import("../views/superadmin/OrganizationInfo.vue")
  },
  card: {
    path: "/admin/card",
    name: routesName.admin.card,
    meta: { authRequired: true, roles: [RoleModel.ROLE.superAdmin] },
    component: () => import("../views/superadmin/Card.vue")
  },
  createUser: {
    path: "/admin/user/create",
    name: routesName.admin.createUser,
    meta: { authRequired: true, roles: [RoleModel.ROLE.superAdmin] },
    component: () => import("../views/superadmin/UserInfo.vue")
  },
  updateUser: {
    path: "/admin/user/update/:userId",
    name: routesName.admin.updateUser,
    meta: { authRequired: true, roles: [RoleModel.ROLE.superAdmin] },
    component: () => import("../views/superadmin/UserInfo.vue")
  },
  createCards: {
    path: "/admin/card/create",
    name: routesName.admin.createCards,
    meta: { authRequired: true, roles: [RoleModel.ROLE.superAdmin] },
    component: () => import("../views/superadmin/CreateCard.vue")
  },
  report: {
    path: "/admin/report",
    name: routesName.admin.report,
    meta: {
      authRequired: true,
      roles: [RoleModel.ROLE.superAdmin]
    },
    component: () => import("../views/superadmin/Report.vue")
  },
  // User Role
  userDashboard: {
    path: "/admin/dashboard/user/:userId",
    name: routesName.admin.userDashboard,
    meta: { authRequired: true, roles: [RoleModel.ROLE.user] },
    component: () => import("../views/user/Dashboard.vue")
  },
  userReport: {
    path: "/admin/report/user/:userId",
    name: routesName.admin.userReport,
    meta: { authRequired: true, roles: [RoleModel.ROLE.user] },
    component: () => import("../views/user/Report.vue")
  },
  updateProfile: {
    path: "/admin/profile/:userId",
    name: routesName.admin.updateProfile,
    meta: {
      authRequired: true,
      roles: [RoleModel.ROLE.user, RoleModel.ROLE.editor, RoleModel.ROLE.admin]
    },
    component: () => import("../views/user/UserInfo.vue")
  },
  //Not Found
  notFound: {
    path: "/:pathMatch(.*)*",
    name: routesName.notFound,
    meta: { authRequired: false },
    component: () => import("../views/NotFound.vue")
  }
};
