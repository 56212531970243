export default {
  total: 0,
  pageSize: 10,
  data: [],
  options: [],
  isFetching: false,
  loading: false,
  isSuccess: false,
  detail: {},
  error: "",
  columns: [
    {
      title: "STT",
      dataIndex: "key",
      key: "key",
      align: "center",
      width: 60
    },
    {
      title: "Họ tên",
      dataIndex: "name",
      key: "name"
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email"
    },
    {
      title: "Số điện thoại",
      dataIndex: "phone",
      key: "phone",
      align: "center"
    },
    {
      title: "Địa chỉ",
      dataIndex: "address",
      key: "address"
    },
    {
      title: "Tên công ty",
      dataIndex: "companyName",
      key: "companyName"
    },
    {
      title: "Ngày tạo",
      key: "createdAt",
      dataIndex: "createdAt",
      align: "center"
    },
    {
      title: "Thao tác",
      key: "tableAction",
      fixed: "right",
      align: "center"
    }
  ]
};
