export default {
  total: 0,
  pageSize: 10,
  data: [],
  options: [],
  isFetching: false,
  loading: false,
  isSuccess: false,
  detail: {},
  error: "",
  columns: [
    {
      title: "STT",
      dataIndex: "key",
      key: "key",
      align: "center",
      width: 60
    },
    {
      title: "Tên viết tắt",
      dataIndex: "code",
      key: "code",
      align: "center"
    },
    {
      title: "Tên tổ chức",
      dataIndex: "name",
      key: "name",
      align: "center"
    },
    {
      title: "MST",
      dataIndex: "taxNumber",
      key: "taxNumber",
      align: "right"
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email"
    },
    {
      title: "SL người dùng",
      dataIndex: "cardCount",
      key: "cardCount",
      align: "right"
    },
    {
      title: "SĐT",
      dataIndex: "phone",
      key: "phone",
      align: "right"
    },
    {
      title: "Trạng thái",
      dataIndex: "active",
      key: "active"
    },
    {
      title: "Thao tác",
      key: "tableAction",
      width: 200,
      align: "center"
    }
  ]
};
