import changePasswordUseCase from "@/core/domain/usecases/account/change-password";
import forgotPasswordUseCase from "@/core/domain/usecases/account/forgot-password";
import loginUseCase from "@/core/domain/usecases/account/login";
import logoutUseCase from "@/core/domain/usecases/account/logout";
import registerUseCase from "@/core/domain/usecases/account/register";
import resetPasswordUseCase from "@/core/domain/usecases/account/reset-password";
import updateDeviceInfoUseCase from "@/core/domain/usecases/account/update-device-info";

import { ALERT_ERROR, ALERT_SUCCESS } from "@/core/presentation/actions/alert";
import { getDefaultRoute, routes } from "@/router/routes";

import { getLoginAccount } from "@/core/utils/auth";
import router from "@/router";

export default {
  async setCurrentLoginAccount({ commit }) {
    const loginAccount = getLoginAccount();
    commit("SET_CURRENT_LOGIN_ACCOUNT", loginAccount);
    if (loginAccount?.id) {
      await updateDeviceInfoUseCase(loginAccount.id);
    }
  },
  async login({ commit, dispatch }, data) {
    commit("SET_LOADING");
    const result = await loginUseCase(data);
    if (result.isSuccess) {
      commit("ACCOUNT_LOGIN_SUCCESS", result.data);
      return router.replace(getDefaultRoute());
    }
    commit("ACCOUNT_LOGIN_ERROR");

    return dispatch(ALERT_ERROR, result.message, { root: true });
  },
  async logout({ commit, state }) {
    logoutUseCase(state.loginAccount?.id);
    commit("ACCOUNT_LOGOUT_SUCCESS");
    router.push(routes.login);
  },
  async changePassword({ commit, dispatch, state }, values) {
    commit("SET_LOADING");
    const result = await changePasswordUseCase(state.loginAccount.id, values);
    commit("SET_SUCCESS_STATUS", result.isSuccess);
    if (result.isSuccess) {
      return dispatch(ALERT_SUCCESS, result.message, { root: true });
    }

    return dispatch(ALERT_ERROR, result.message, { root: true });
  },
  async resetPassword({ commit, dispatch }, values) {
    commit("SET_LOADING");
    const result = await resetPasswordUseCase(values);
    commit("SET_SUCCESS_STATUS", result.isSuccess);

    if (!result.isSuccess) {
      return dispatch(ALERT_ERROR, result.message, { root: true });
    }
    router.replace(routes.login);
  },
  async forgotPassword({ commit, dispatch }, email) {
    commit("SET_LOADING");
    const result = await forgotPasswordUseCase(email);
    commit("SET_SUCCESS_STATUS", result.isSuccess);
    if (!result.isSuccess) {
      return dispatch(ALERT_ERROR, result.message, { root: true });
    }
  },
  async registerAccount({ commit, dispatch }, data) {
    commit("SET_LOADING");
    const result = await registerUseCase(data);
    if (result.isSuccess) return commit("REGISTER_ACCOUNT_SUCCESS", result.data);

    dispatch(ALERT_ERROR, result.message, { root: true });
    return commit("REGISTER_ACCOUNT_ERROR", result.message);
  }
};
