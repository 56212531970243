import organizationState from "./state";
import { tableOrgValue } from "@/core/domain/models/organization-model";

export default {
  GET_ACTIVE_ORGANIZATION(state = organizationState, { data }) {
    state.options = data?.map((item) => ({ label: item.name, value: item.id }));
  },
  GET_ORGANIZATION_FETCHING(state = organizationState) {
    state.isFetching = true;
    state.data = [];
    state.isSuccess = false;
  },
  GET_ORGANIZATION_ERROR(state = organizationState, message) {
    state.isFetching = false;
    state.error = message;
  },
  GET_ORGANIZATION_SUCCESS(state = organizationState, { total, data, pageSize }) {
    state.isFetching = false;
    state.error = "";
    state.total = total;
    state.pageSize = pageSize;
    state.data = data;
  },
  SET_ORGANIZATION_LOADING(state = organizationState) {
    state.isSuccess = false;
    state.loading = true;
  },
  CREATE_ORGANIZATION_SUCCESS(state = organizationState, values) {
    state.data.unshift(tableOrgValue(values));
    state.loading = false;
    state.isSuccess = true;
  },
  CREATE_ORGANIZATION_ERROR(state = organizationState, message) {
    state.error = message;
    state.loading = false;
    state.isSuccess = false;
  },
  UPDATE_ORGANIZATION_SUCCESS(state = organizationState, values) {
    const index = state.data.findIndex((item) => item.id === values.id);
    state.data.splice(index, 1, tableOrgValue(values));
    state.loading = false;
    state.isSuccess = true;
  },
  UPDATE_ORGANIZATION_ERROR(state = organizationState, message) {
    state.error = message;
    state.loading = false;
    state.isSuccess = false;
  },
  REMOVE_ORGANIZATION_SUCCESS(state = organizationState, id) {
    const index = state.data.findIndex((item) => item.id === id);
    if (index !== -1) {
      state.data.splice(index, 1);
    }
    state.isSuccess = true;
    state.loading = false;
  },
  REMOVE_ORGANIZATION_ERROR(state = organizationState, message) {
    state.error = message;
    state.loading = false;
    state.isSuccess = false;
  },
  GET_ORGANIZATION_DETAIL_FETCHING(state = organizationState) {
    state.isSuccess = false;
    state.isFetching = true;
    state.detail = {};
  },
  GET_ORGANIZATION_DETAIL_SUCCESS(state = organizationState, data) {
    state.detail = data;
    state.isFetching = false;
  },
  GET_ORGANIZATION_DETAIL_ERROR(state = organizationState, message) {
    state.error = message;
    state.isSuccess = false;
    state.isFetching = false;
    state.detail = {};
  }
};
