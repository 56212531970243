<template>
  <metainfo>
    <template v-slot:title="{ content }">{{ content }}</template>
  </metainfo>
  <router-view />
  <NotificationAlert />
</template>

<script>
import NotificationAlert from "@/components/common/NotificationAlert.vue";

import { SET_CURRENT_LOGIN_ACCOUNT } from "@/core/presentation/actions/account";
import { ALERT_CLEAR } from "@/core/presentation/actions/alert";
import { SCREEN_RESIZE } from "@/core/presentation/actions/screen";
import { message } from "ant-design-vue";
import { useMeta } from "vue-meta";
import { mapState, useStore } from "vuex";

export default {
  name: "FuviorApp",
  components: { NotificationAlert },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.onResize);
  },
  setup() {
    const store = useStore();
    useMeta({
      title: "Fuvior Admin",
      link: [
        { rel: "icon", href: "https://api.fuvior.com/logo/fuvior/favicon.ico" },
        {
          rel: "apple-touch-icon", href: "https://api.fuvior.com/logo/fuvior/shortcut-ic.png"
        }
      ]
    }
    );

    const onResize = () => {
      store.dispatch(SCREEN_RESIZE, {
        innerWidth: window.innerWidth,
        innerHeight: window.innerHeight
      });
      return;
    };

    return { store: useStore(), onResize };
  },

  beforeCreate() {
    this.store.dispatch(SET_CURRENT_LOGIN_ACCOUNT);
  },
  computed: {
    ...mapState({
      message: (state) => state.alert.message,
      type: (state) => state.alert.type,
    })
  },
  watch: {
    message: function (nextState) {
      if (nextState) {
        message[this.type]({
          content: nextState,
          onClose: () => this.store.dispatch(ALERT_CLEAR)
        });
      }
    }
  }
};
</script>