export default {
  total: 0,
  pageSize: 10,
  data: [],
  options: [],
  isFetching: false,
  error: "",
  isSuccess: false,
  loading: false,
  unreadCount: 0,
  newNotification: {
    visible: false,
    data: {}
  },
  columns: [
    {
      title: "STT",
      dataIndex: "key",
      key: "key",
      align: "center",
      width: 60
    },
    {
      title: "ID",
      dataIndex: "id",
      key: "id"
    },
    {
      title: "Thứ tự",
      dataIndex: "order",
      key: "order",
      align: "center"
    },
    {
      title: "Thumbnail",
      dataIndex: "thumbnail",
      key: "thumbnail"
    },
    {
      title: "Mẫu template",
      dataIndex: "name",
      key: "name"
    },
    {
      title: "Ngày tạo",
      dataIndex: "createdAt",
      key: "createdAt"
    },
    {
      title: "Loại template",
      dataIndex: "category",
      key: "category"
    },
    {
      title: "Trạng thái",
      key: "active",
      dataIndex: "active",
      align: "center"
    },
    {
      title: "Thao tác",
      key: "tableAction",
      width: 150,
      align: "center"
    }
  ]
};
