<template>
  <PhoneDisplay>
    <iframe id="reviewProfile" :src="src" />
  </PhoneDisplay>
</template>
<script>
import PhoneDisplay from "@/components/layout/template/PhoneDisplay.vue";
import { computed, watch } from "vue";

export default {
  name: "UserReviewTemplate",
  props: ["profile"],
  components: { PhoneDisplay },
  setup(props) {
    const src = computed(() => props.profile.id ? `/p/${props.profile.id}` : "/p/0");
    const profile = computed(() => props.profile);

    watch(profile, (nextState) => {
      if (nextState) {
        document
          .getElementById("reviewProfile")
          .contentWindow.postMessage(JSON.stringify({ profile: nextState }), "*");
      }
    }, { deep: true });

    return { src };
  }
};
</script>
