<template>
  <div v-if="visible" class="notification-alert-wrapper" @click="() => handleClickNotification(newNotification)">
    <a-alert :message="newNotification.title" type="success" show-icon closable>
      <template #description>
        <div v-html="newNotification.body" />
      </template>
      <template #icon>
        <BellFilled />
      </template>
    </a-alert>
  </div>
</template>

<script setup>
import { handleNotificationSelect } from "@/core/domain/helpers/notification-helper";
import { SET_NEW_NOTIFICATION_VISIBLE } from "@/core/presentation/actions/notification";
import { BellFilled } from "@ant-design/icons-vue";
import { computed } from "vue";
import { useStore } from "vuex";
const store = useStore();
const newNotification = computed(() => store.state.notification.newNotification.data);
const visible = computed(() => store.state.notification.newNotification.visible);
const handleClickNotification = (item) => {
  handleNotificationSelect(item);
  store.dispatch(SET_NEW_NOTIFICATION_VISIBLE, false);
};
</script>
<style scoped>
.notification-alert-wrapper {
  position: fixed;
  bottom: 5%;
  right: 16px;
  width: 30vw;
  z-index: 999;
}
</style>
