import store from "@/store";

import { FIREBASE_CONFIG, FIREBASE_VAPID_KEY } from "@/config";
import { GET_NEW_NOTIFICATION } from "@/core/presentation/actions/notification";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

if (window.Notification && Notification.permission === "granted") {
  // Initialize Firebase
  const app = initializeApp(FIREBASE_CONFIG);
  const messaging = getMessaging(app);
  onMessage(messaging, (payload) => {
    // console.log("Message received. ", payload.data.notify);
    try {
      const notify = JSON.parse(payload.data.notify);
      if (notify) store.dispatch(GET_NEW_NOTIFICATION, notify);
    } catch (error) {
      //
    }
  });
}

export async function getFirebaseToken() {
  try {
    if (window.Notification && Notification.permission === "granted") {
      const app = initializeApp(FIREBASE_CONFIG);
      const messaging = getMessaging(app);
      return await getToken(messaging, { vapidKey: FIREBASE_VAPID_KEY });
    }
    return "";
  } catch (error) {
    console.log("An error occurred while retrieving token. ", error);
    return "";
  }
}
