import { API_HEADERS, API_HOST } from "@/config";
import apiCode from "../services/api-services/api-code";
import ApiService from "../services/api-services/api-service";

export default class NotificationApi {
  #apiService = new ApiService(API_HOST, API_HEADERS);

  async getNotificationListPagination(query) {
    const result = await this.#apiService.get("/notification/pagination", query);

    return {
      isSuccess: result.code === apiCode.success,
      status: result.status,
      code: result.code,
      message: result.message,
      data: {
        items: result.data.items || [],
        total: Number(result.data.total) || 0,
        limit: Number(result.data.limit),
        unreadCount: result.data.unreadCount || 0
      }
    };
  }

  async createNotification(body) {
    const result = await this.#apiService.post("/notification", body);
    return {
      isSuccess: result.code === apiCode.success,
      status: result.status,
      code: result.code,
      message: result.message,
      data: result.data
    };
  }

  async updateNotification(id, body) {
    const result = await this.#apiService.put(`/notification/${id}`, body);
    return {
      isSuccess: result.code === apiCode.success,
      status: result.status,
      code: result.code,
      message: result.message,
      data: result.data
    };
  }
}
