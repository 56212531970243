import crypto from "crypto-js";

function sha256(data) {
  return crypto.SHA256(data).toString();
}

function hmacSha256(hashData, hashKey) {
  return crypto.HmacSHA256(hashData, hashKey).toString();
}

export default {
  sha256,
  hmacSha256
};
