import { API_HOST, API_HEADERS } from "@/config";
import apiCode from "../services/api-services/api-code";
import ApiService from "../services/api-services/api-service";

export default class OrganizationApi {
  #apiService = new ApiService(API_HOST, API_HEADERS);

  async getOrganizationListPagination(query) {
    const result = await this.#apiService.get("/organization/pagination", query);
    return {
      isSuccess: result.code === apiCode.success,
      status: result.status,
      code: result.code,
      message: result.message,
      data: {
        items: result.data.items || [],
        total: Number(result.data.total) || 0,
        limit: Number(result.data.limit) || 10
      }
    };
  }

  async getOrganizationList(query) {
    const result = await this.#apiService.get("/organization", query);
    return {
      isSuccess: result.code === apiCode.success,
      status: result.status,
      code: result.code,
      message: result.message,
      data: {
        items: result.data.items || []
      }
    };
  }

  async getOrganizationDetail(id) {
    const result = await this.#apiService.get(`/organization/detail/${id}`);
    return {
      isSuccess: result.code === apiCode.success,
      status: result.status,
      code: result.code,
      message: result.message,
      data: result.data
    };
  }

  async createOrganization(body) {
    const result = await this.#apiService.post("/organization", body);
    return {
      isSuccess: result.code === apiCode.success,
      status: result.status,
      code: result.code,
      message: result.message,
      data: result.data
    };
  }

  async updateOrganization(id, body) {
    const result = await this.#apiService.put(`/organization/${id}`, body);
    return {
      isSuccess: result.code === apiCode.success,
      status: result.status,
      code: result.code,
      message: result.message,
      data: result.data
    };
  }

  async removeOrganization(id) {
    const result = await this.#apiService.remove(`/organization/${id}`);
    return {
      ...{ isSuccess: result.code === apiCode.success },
      ...result
    };
  }
}
