import { API_HEADERS, API_HOST } from "@/config";
import apiCode from "../services/api-services/api-code";
import ApiService from "../services/api-services/api-service";

export default class {
  #apiService = new ApiService(API_HOST, API_HEADERS);

  async getLeadFormListPagination(query) {
    const result = await this.#apiService.get("/lead-form/pagination", query);
    return {
      isSuccess: result.code === apiCode.success,
      status: result.status,
      code: result.code,
      message: result.message,
      data: {
        items: result.data.items || [],
        total: Number(result.data.total) || 0,
        columns: result.data.columns || [],
        limit: Number(result.data.limit) || 10
      }
    };
  }

  async createLeadForm(body) {
    const result = await this.#apiService.post("/lead-form", body);
    return {
      isSuccess: result.code === apiCode.success,
      status: result.status,
      code: result.code,
      message: result.message,
      data: result.data
    };
  }

  async removeLeadForm(id) {
    const result = await this.#apiService.remove(`/lead-form/${id}`);
    return {
      ...{ isSuccess: result.code === apiCode.success },
      ...result
    };
  }
}
