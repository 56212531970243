export default {
  total: 0,
  pageSize: 10,
  data: [],
  options: [],
  isFetching: false,
  loading: false,
  isSuccess: false,
  detail: {},
  error: "",
  columns: [
    {
      title: "STT",
      dataIndex: "key",
      key: "key",
      align: "center",
      width: 60
    },
    {
      title: "Thao tác",
      key: "tableAction",
      fixed: "right",
      align: "center"
    }
  ]
};
