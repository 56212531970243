<template>
  <div class="modal fade" id="share-social" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <a-alert banner v-if="alertVisible" :message="t('action.copySuccess')" type="success" />

        <div class="modal-header">
          <h5 class="modal-title" id="tab-content-system">Chia sẻ</h5>
          <button type="button" class="close" data-dismiss="modal">
            <i class="ti-close"></i>
          </button>
        </div>
        <div class="modal-body">
          <a class="social-share" :href="profile.shareToFb" target="_blank">
            <img src="/assets/images/ic-share-fb.png" /><br />
            <span>Facebook</span>
          </a>
          <a class="social-share" :href="profile.shareToMessage" target="_blank">
            <img src="/assets/images/ic-share-mess.png" />
            <br />
            <span>Messenger</span>
          </a>
          <a class="social-share" :href="profile.shareToLinkedIn" target="_blank">
            <img src="/assets/images/ic-share-lindk.png" />
            <br />
            <span>LinkedIn</span>
          </a>
          <a class="social-share" :href="profile.shareToTwitter" target="_blank">
            <img src="/assets/images/ic-share-twitter.png" />
            <br />
            <span>Twitter</span>
          </a>
          <a class="social-share" :href="profile.shareToPinterest" target="_blank">
            <img src="/assets/images/ic-share-pinterest.png" /><br />
            <span>Pinterest</span>
          </a>
          <a class="social-share" id="zalo-btn" target="_blank">
            <div id="zalo-share-btn" class="zalo-share-button" v-bind:data-href="profile.shareToZalo"
              data-oaid="3032421126048184161" data-layout="3" data-color="blue" data-customize="false"
              data-share-type="2"></div>
            <br />
            <span>Zalo</span>
          </a>
          <a class="social-share" :href="profile.shareToWhatsapp" data-action="share/whatsapp/share" target="_blank">
            <img src="/assets/images/ic-share-whatsapp.png" />
            <br />
            <span>Whatsapp</span>
          </a>
          <a class="social-share" :href="profile.shareToTelegram" target="_blank">
            <img src="/assets/images/ic-share-tele.png" />
            <br />
            <span>Telegram</span>
          </a>
          <p class="title-copy-link"></p>
          <div class="copy-link">
            <input type="text" placeholder="http://" :value="profile.url" />
            <img src="/assets/images/ic-copy-link.png" @click="handleCopyProfileUrl" style="
                background: white;
                padding: 7px;
                width: 40px;
                height: 40px;
                top: 2px;
                right: 2px;
                border-top-right-radius: 15px;
                border-bottom-right-radius: 15px;" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { profileModel } from "@/core/domain/models/profile-model";
import { computed, defineComponent, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";

export default defineComponent({
  beforeMount() {
    const zaloSdk = document.createElement("script");
    zaloSdk.setAttribute("src", "https://sp.zalo.me/plugins/sdk.js");
    document.head.appendChild(zaloSdk);
  },
  mounted() {
    let counter = 0;
    const maxRetry = 200;

    function refreshZaloBtn(interval) {
      setTimeout(() => {
        const zalo = document.getElementById("zalo-share-btn");

        if (++counter > maxRetry) return clearTimeout(refreshZaloBtn);

        if (!zalo || !zalo.firstChild) return refreshZaloBtn(interval);

        if (!zalo.firstChild.src?.includes("dev-")) return clearTimeout(refreshZaloBtn);

        zalo.firstChild.src = zalo.firstChild.src.replace("dev-", "");
        zalo.style.width = "32px";
        zalo.style.height = "32px";
        return clearTimeout(refreshZaloBtn);
      }, interval);
    }

    refreshZaloBtn(100);
  },
  setup() {
    const store = useStore();
    const profile = computed(() => {
      const data = store.state.profile.detail.id
        ? store.getters["profile/detail"]
        : store.getters["user/detail"];
      return profileModel(data);
    });
    const alertVisible = ref(false);
    watch(alertVisible, (nextState) => {
      if (nextState === true)
        setTimeout(() => {
          alertVisible.value = false;
        }, 2000);
    });

    const handleCopyProfileUrl = () => {
      navigator.clipboard.writeText(profile.value.url);
      alertVisible.value = true;
    };


    return {
      t: useI18n().t,
      handleCopyProfileUrl,
      profile,
      alertVisible,
    };
  }
});
</script>
