import templateModel from "@/core/domain/models/template-model";
import templateState from "./state";

export default {
  GET_ACTIVE_TEMPLATE(state = templateState, { data }) {
    state.options = data;
  },
  GET_TEMPLATE_FETCHING(state = templateState) {
    state.isFetching = true;
    state.data = [];
  },
  GET_TEMPLATE_ERROR(state = templateState, message) {
    state.isFetching = false;
    state.error = message;
  },
  GET_TEMPLATE_SUCCESS(state = templateState, { total, data, pageSize }) {
    state.isFetching = false;
    state.error = "";
    state.total = total;
    state.pageSize = pageSize;
    state.data = data;
  },
  SET_TEMPLATE_LOADING(state = templateState) {
    state.isSuccess = false;
    state.loading = true;
  },
  CREATE_TEMPLATE_SUCCESS(state = templateState, values) {
    state.data.unshift(templateModel.tableValues(values));
    state.isSuccess = true;
    state.loading = false;
  },
  CREATE_TEMPLATE_ERROR(state = templateState, message) {
    state.error = message;
    state.isSuccess = false;
    state.loading = false;
  },
  UPDATE_TEMPLATE_SUCCESS(state = templateState, values) {
    const index = state.data.findIndex((item) => item.id === values.id);
    state.data.splice(index, 1, templateModel.tableValues(values));
    state.isSuccess = true;
    state.loading = false;
  },
  UPDATE_TEMPLATE_ERROR(state = templateState, message) {
    state.error = message;
    state.isSuccess = false;
    state.loading = false;
  },
  REMOVE_TEMPLATE_SUCCESS(state = templateState, id) {
    state.data = state.data.filter((item) => item.id !== id);
    state.isSuccess = true;
    state.loading = false;
  },
  REMOVE_TEMPLATE_ERROR(state = templateState, message) {
    state.error = message;
    state.isSuccess = false;
    state.loading = false;
  }
};
