<template>
  <a-button type="primary" html-type="submit">
    <a-space align="center">
      <img v-if="icon === 'check'" src="/assets/icons/check-ic.svg" />
      <span style="font-size: 14; font-weight: 700; text-transform: uppercase;">{{ buttonText }}</span>
    </a-space>
  </a-button>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  props: ["buttonText", "icon"],
});
</script>
