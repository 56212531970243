<template>
  <a-form autocomplete="off" :model="formState" :rules="rules" ref="formRef" layout="vertical" @finish="onFinish">
    <a-form-item has-feedback :label="t('form.changePassword.label.oldPassword')" name="oldPassword">
      <a-input autocomplete="old-password" :placeholder="t('form.changePassword.placeholder.oldPassword')" type="password"
        v-model:value="formState.oldPassword" />
    </a-form-item>
    <a-form-item has-feedback :label="t('form.changePassword.label.newPassword')" name="newPassword">
      <a-input autocomplete="new-password" :placeholder="t('form.changePassword.placeholder.newPassword')" type="password"
        v-model:value="formState.newPassword" />
    </a-form-item>
    <a-form-item has-feedback :label="t('form.changePassword.label.confirmNewPassword')" name="confirmNewPassword">
      <a-input autocomplete="confirm-new-password" :placeholder="t('form.changePassword.placeholder.newPassword')"
        type="password" v-model:value="formState.confirmNewPassword" />
    </a-form-item>
    <a-form-item>
      <SaveButton :loading="loading">{{ t(`action.save`) }}</SaveButton>
    </a-form-item>

  </a-form>
</template>
<script>
import { ACCOUNT_CHANGE_PASSWORD } from "@/core/presentation/actions/account";
import { computed, reactive, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";

export default {
  name: "ChangePasswordForm",
  watch: {
    isSuccess: function (nextState) {
      if (nextState === true) {
        this.formRef.resetFields();
      }
    }
  },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const loading = computed(() => store.state.account.loading);
    const isSuccess = computed(() => store.state.account.isSuccess);
    const formState = reactive({ oldPassword: "", newPassword: "", confirmNewPassword: "" });
    const formRef = ref();
    const rules = {
      oldPassword: [
        {
          required: true,
          message: `${t("form.changePassword.placeholder.oldPassword")} ${t(
            "formValidation.isRequired"
          )}`
        }
      ],
      newPassword: [
        {
          required: true,
          message: `${t("form.changePassword.placeholder.newPassword")} ${t(
            "formValidation.isRequired"
          )}`
        }
      ],
      confirmNewPassword: [
        {
          required: true,
          message: `${t("form.changePassword.placeholder.confirmNewPassword")} ${t(
            "formValidation.isRequired"
          )}`
        },
        {
          validator: async (_rule, value) => {
            if (value !== formState.newPassword) {
              formRef.value.validateFields("newPassword");
              return Promise.reject(t("formValidation.confirmNewPassword"));
            }
            return Promise.resolve();
          },
          trigger: "blur"
        }
      ]
    };

    const onFinish = (values) => {
      store.dispatch(ACCOUNT_CHANGE_PASSWORD, values);
    };


    return {
      t,
      onFinish,
      formState,
      store,
      loading,
      rules,
      formRef,
      isSuccess
    };
  }
};
</script>
