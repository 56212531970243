import createTemplateUseCase from "@/core/domain/usecases/template/create-template";
import getActiveTemplateUseCase from "@/core/domain/usecases/template/get-active-template-list";
import getTemplateListUseCase from "@/core/domain/usecases/template/get-template-list";
import removeTemplateUseCase from "@/core/domain/usecases/template/remove-template";
import updateTemplateUseCase from "@/core/domain/usecases/template/update-template";
import { ALERT_ERROR, ALERT_SUCCESS } from "../../actions/alert";

export default {
  async getActiveTemplate({ commit }) {
    const result = await getActiveTemplateUseCase();
    commit("GET_ACTIVE_TEMPLATE", { data: result.data.items || [] });
  },
  async getTemplateList({ commit, dispatch }, query) {
    commit("GET_TEMPLATE_FETCHING");
    const result = await getTemplateListUseCase(query);
    if (result.isSuccess) {
      return commit("GET_TEMPLATE_SUCCESS", {
        total: result.data.total,
        pageSize: result.data.limit,
        data: result.data.items
      });
    }
    dispatch(ALERT_ERROR, result.message, { root: true });
    return commit("GET_TEMPLATE_ERROR", result.message);
  },
  async removeTemplate({ commit, dispatch }, id) {
    commit("SET_TEMPLATE_LOADING");
    const result = await removeTemplateUseCase(id);
    if (result.isSuccess) {
      dispatch(ALERT_SUCCESS, result.message, { root: true });
      return commit("REMOVE_TEMPLATE_SUCCESS", id);
    }

    dispatch(ALERT_ERROR, result.message, { root: true });
    return commit("REMOVE_TEMPLATE_ERROR", result.message);
  },
  async updateTemplate({ commit, dispatch }, data) {
    commit("SET_TEMPLATE_LOADING");

    const result = await updateTemplateUseCase(data);
    if (result.isSuccess) {
      dispatch(ALERT_SUCCESS, result.message, { root: true });
      return commit("UPDATE_TEMPLATE_SUCCESS", result.data);
    }

    dispatch(ALERT_ERROR, result.message, { root: true });
    return commit("UPDATE_TEMPLATE_ERROR", result.message);
  },
  async createTemplate({ commit, dispatch }, data) {
    commit("SET_TEMPLATE_LOADING");

    const result = await createTemplateUseCase(data);
    if (result.isSuccess) {
      dispatch(ALERT_SUCCESS, result.message, { root: true });
      return commit("CREATE_TEMPLATE_SUCCESS", result.data);
    }

    dispatch(ALERT_ERROR, result.message, { root: true });
    return commit("CREATE_TEMPLATE_ERROR", result.message);
  }
};
