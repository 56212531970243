export default {
  totalUserCount: (state) => state.totalUserCount,
  viewProfileCount: (state) => {
    return state.trackingResumeCount.viewProfile;
  },
  saveProfileCount: (state) => {
    return state.trackingResumeCount.saveProfile;
  },
  leadCount: (state) => {
    return state.trackingResumeCount.lead;
  },
  exchangeContactCount: (state) => {
    return state.trackingResumeCount.exchangeContact;
  }
};
