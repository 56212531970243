<template>
  <div
    class="modal fade"
    id="scan-qr"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal">
            <i class="ti-close"></i>
          </button>
        </div>
        <div class="modal-body">
          <div class="content-qr">
            <img :src="qrcode" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  props: ["avatar", "qrcode"]
});
</script>
