<template>
  <img :src="toggleIcon" @click="handleCollapse" />
</template>

<script>
import { defineComponent, ref } from "vue";
import { COLLAPSE_IC, EXPAND_IC } from "../icons/path";

export default defineComponent({
  props: ["collapse"],
  emits: ["update:collapse"],
  setup(props, { emit }) {
    const toggleIcon = ref(EXPAND_IC);
    const handleCollapse = () => {
      emit("update:collapse", !props.collapse);

      if (props.collapse)
        return toggleIcon.value = EXPAND_IC;

      return toggleIcon.value = COLLAPSE_IC;
    };

    return {
      toggleIcon,
      handleCollapse
    };
  }
});
</script>
