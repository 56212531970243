<template>
  <div id="admin-header">
    <div class="logo">
      <img :src="HEADER_MENU_LOGO" />
    </div>
    <NotificationHeader />
    <div id="admin-header-desktop">
      <a-menu mode="horizontal" :subMenuCloseDelay="500" v-model:selectedKeys="selectedKeys">
        <a-menu-item v-for="value in menuList" :key="value.key" :title="value.title">
          <div class="menu" @click="handleSelectMenu(value)">
            <DesktopMenuIcon class="icon-color" :icon="value.icon" />
            <div class="title">{{ value.title }}</div>
          </div>
        </a-menu-item>
      </a-menu>
      <a-dropdown :trigger="['click', 'hover']">
        <a-space class="right-dropdown-menu">
          <img src="/assets/images/profile-image.svg" />
          <img src="/assets/icons/chevron-down-white-ic.svg" />
        </a-space>
        <template #overlay>
          <a-menu id="dropdown-overlay">
            <a-menu-item>
              <label class="account-name">{{ loginAccount.name }}</label><br />
              <label class="account-email">{{ loginAccount.account }}</label><br />
              <label class="account-email">{{ loginAccount.role }}</label>
            </a-menu-item>
            <a-menu-divider />
            <a-menu-item @click="goToAccountInfo()">
              <img src="/assets/icons/user-ic.svg" /> {{ t("accountInfo") }}
            </a-menu-item>
            <a-menu-item @click="handleModalVisible(true)">
              <img src="/assets/icons/lock-ic.svg" /> {{ t("changePassword") }}
            </a-menu-item>
            <a-menu-item @click="logout">
              <img src="/assets/icons/log-out-ic.svg" /> {{ t("logoutText") }}
            </a-menu-item>
          </a-menu>
        </template>
      </a-dropdown>
    </div>
    <div id="admin-header-mobile">
      <img class="icon" @click="handleMobileMenuVisible" v-if="mobileMenuVisible"
        src="/assets/icons/menu/mobile/menu-close-ic.svg" />
      <img class="icon" @click="handleMobileMenuVisible" v-else src="/assets/icons/menu/mobile/menu-ic.svg" />
      <div v-if="mobileMenuVisible" class="mobile-menu-wrapper">
        <a-row align="center" :gutter="[16, 16]">
          <a-col :span="12" v-for="value in menuList" :key="value.key">
            <div @click="handleSelectMenu(value)" class="mobile-menu-item">
              <a-space>
                <MobileMenuIcon class="icon-color" :icon="value.icon" />
                <div>{{ value.title }}</div>
              </a-space>
            </div>
          </a-col>
        </a-row>
        <a-divider style="border-color: #C4C8D3"></a-divider>
        <a-dropdown :trigger="['click', 'hover']">
          <a-space>
            <img src="/assets/images/profile-image.svg" />
            <span>Tài khoản</span>
            <img src="/assets/icons/chevron-down-ic.svg" />
          </a-space>
          <template #overlay>
            <a-menu id="dropdown-overlay">
              <a-menu-item>
                <label class="account-name">{{ loginAccount.name }}</label><br />
                <label class="account-email">{{ loginAccount.account }}</label><br />
                <label class="account-email">{{ loginAccount.role }}</label>
              </a-menu-item>
              <a-menu-divider />
              <a-menu-item @click="goToAccountInfo()">
                <img src="/assets/icons/user-ic.svg" /> {{ t("accountInfo") }}
              </a-menu-item>
              <a-menu-item @click="handleModalVisible(true)">
                <img src="/assets/icons/lock-ic.svg" /> {{ t("changePassword") }}
              </a-menu-item>
              <a-menu-item @click="logout">
                <img src="/assets/icons/log-out-ic.svg" /> {{ t("logoutText") }}
              </a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>
      </div>
    </div>
  </div>

  <a-modal id="admin-modal" :footer="false" v-model:visible="changePasswordVisible" @ok="handleModalVisible(false)">
    <div class="modal-title">{{ t("action.changePassword") }}</div>
    <ChangePasswordForm />
  </a-modal>
</template>

<script>
import ChangePasswordForm from "@/components/ChangePasswordForm.vue";
import DesktopMenuIcon from "@/components/layout/header/DesktopMenuIcon.vue";
import MobileMenuIcon from "@/components/layout/header/MobileMenuIcon.vue";
import NotificationHeader from "@/components/layout/header/NotificationHeader.vue";

import { HEADER_MENU_LOGO } from "@/config/index";
import { MENU_ITEM } from "@/core/domain/helpers/menu-helper.js";
import { ACCOUNT_LOGOUT } from "@/core/presentation/actions/account";
import { routes } from "@/router/routes";
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";

export default {
  // eslint-disable-next-line vue/no-unused-components
  components: { ChangePasswordForm, NotificationHeader, DesktopMenuIcon, MobileMenuIcon },
  name: "AdminHeader",
  watch: {
    isSuccess: function (nextState) {
      if (nextState === true) {
        this.handleModalVisible(false);
      }
    }
  },
  setup() {
    const { t } = useI18n();
    const router = useRouter();
    const route = useRoute();
    const store = useStore();

    const changePasswordVisible = ref(false);
    const mobileMenuVisible = ref(false);
    const isSuccess = computed(() => store.state.account.isSuccess);
    const menuList = computed(() => store.state.account.menuList);
    const loginAccount = computed(() => store.state.account.loginAccount);
    const isSuperAdmin = computed(() => store.getters["account/isSuperAdmin"]);
    const isAdmin = computed(() => store.getters["account/isAdmin"]);

    let selectedKeys = [route.name];
    if (
      //select organization menu (admin)
      isAdmin.value &&
      (
        route.name === routes.organizationEmployee.name ||
        route.name === routes.updateEmployee.name ||
        route.name === routes.createEmployee.name
      )
    ) {
      selectedKeys = [MENU_ITEM.organizationEmployee.key];
    }
    else if (isSuperAdmin.value) {
      if (
        //select user menu (danh bạ) (superadmin)
        route.name === routes.createUser.name ||
        route.name === routes.updateUser.name
      ) {
        selectedKeys = [MENU_ITEM.user.key];
      } else if (
        //select organization menu (superadmin)
        route.name === routes.createOrganization.name ||
        route.name === routes.updateOrganization.name ||
        route.name === routes.organizationEmployee.name ||
        route.name === routes.createEmployee.name
      ) {
        selectedKeys = [MENU_ITEM.organization.key];
      }
    }
    else {
      selectedKeys = [route.name];
    }

    const logout = () => store.dispatch(ACCOUNT_LOGOUT);
    const goToAccountInfo = () => {
      router.push({ name: routes.updateProfile.name, params: { userId: loginAccount.value.id } });
    };

    const handleModalVisible = (value) => (changePasswordVisible.value = value);
    const handleMobileMenuVisible = () => (mobileMenuVisible.value = !mobileMenuVisible.value);
    const handleSelectMenu = (value) => {
      const route = { name: value.key };
      if (
        value.key === MENU_ITEM.organizationDetail.key ||
        value.key === MENU_ITEM.organizationEmployee.key
      ) {
        route.params = { orgId: loginAccount.value.organization };
      }
      if (value.key === MENU_ITEM.updateProfile.key) {
        route.params = { userId: loginAccount.value.id };
      }

      router.push(route);
    };

    return {
      t,
      menuList,
      selectedKeys,
      loginAccount,
      logout,
      handleSelectMenu,
      handleModalVisible,
      goToAccountInfo,
      changePasswordVisible,
      isSuccess,
      mobileMenuVisible,
      handleMobileMenuVisible,
      HEADER_MENU_LOGO,
    };
  }
};
</script>
