import { tableOrgValue } from "@/core/domain/models/exchange-contact-model";
import leadFormState from "./state";

export default {
  GET_LEAD_FORM_FETCHING(state = leadFormState) {
    state.isFetching = true;
    state.data = [];
    state.isSuccess = false;
  },
  GET_LEAD_FORM_ERROR(state = leadFormState, message) {
    state.isFetching = false;
    state.error = message;
  },
  GET_LEAD_FORM_SUCCESS(state = leadFormState, { total, data, columns, pageSize }) {
    state.isFetching = false;
    state.error = "";
    state.total = total;
    state.pageSize = pageSize;
    state.data = data;
    state.columns = [
      {
        title: "STT",
        dataIndex: "key",
        key: "key",
        align: "center",
        width: 60
      }
    ]
      .concat(columns.map((item) => ({ title: item, key: item, dataIndex: item, align: "center" })))
      .concat([
        {
          title: "Ngày tạo",
          key: "createdAt",
          dataIndex: "createdAt",
          align: "center"
        },
        {
          title: "Thao tác",
          key: "tableAction",
          fixed: "right",
          align: "center"
        }
      ]);
  },
  CREATE_LEAD_FORM_SUCCESS(state = leadFormState, values) {
    state.data.unshift(tableOrgValue(values));
    state.loading = false;
    state.isSuccess = true;
  },
  CREATE_LEAD_FORM_ERROR(state = leadFormState, message) {
    state.error = message;
    state.loading = false;
    state.isSuccess = false;
  },
  REMOVE_LEAD_FORM_SUCCESS(state = leadFormState, id) {
    const index = state.data.findIndex((item) => item.id === id);
    if (index !== -1) {
      state.data.splice(index, 1);
    }
    state.isSuccess = true;
    state.loading = false;
  },
  REMOVE_LEAD_FORM_ERROR(state = leadFormState, message) {
    state.error = message;
    state.loading = false;
    state.isSuccess = false;
  },
  SET_LEAD_FORM_LOADING(state = leadFormState) {
    state.isSuccess = false;
    state.loading = true;
  }
};
