import configDebug from "./ecard/config.debug";
import configDevelopment from "./ecard/config.development";
import configLocal from "./ecard/config.local";
import configProduction from "./ecard/config.production";

import fuviorConfigDebug from "./fuvior/config.debug";
import fuviorConfigDevelopment from "./fuvior/config.development";
import fuviorConfigLocal from "./fuvior/config.local";
import fuviorConfigProduction from "./fuvior/config.production";

const environment = process.env.VUE_APP_ENV;
const project = process.env.VUE_APP_PROJECT;
let defaultConfig = null;
if (project === "ecard") {
  if (environment === "development") {
    defaultConfig = configDevelopment;
  } else if (environment === "production") {
    defaultConfig = configProduction;
  } else if (environment === "debug") {
    defaultConfig = configDebug;
  } else {
    defaultConfig = configLocal;
  }
} else if (project === "fuvior") {
  if (environment === "development") {
    defaultConfig = fuviorConfigDevelopment;
  } else if (environment === "production") {
    defaultConfig = fuviorConfigProduction;
  } else if (environment === "debug") {
    defaultConfig = fuviorConfigDebug;
  } else {
    defaultConfig = fuviorConfigLocal;
  }
}

export const FIREBASE_PROJECT = {
  config: {
    fuvior: {
      apiKey: "AIzaSyDDjtVL48of92iZBTzbiyXwVdIJJ9lXIyE",
      authDomain: "canteen-96f48.firebaseapp.com",
      databaseURL: "https://canteen-96f48.firebaseio.com",
      projectId: "canteen-96f48",
      storageBucket: "canteen-96f48.appspot.com",
      messagingSenderId: "27335103862",
      appId: "1:27335103862:web:02b97d7ef8f1f9999f1657",
      measurementId: "G-NKX85R8DP9"
    },
    ecard: {
      apiKey: "AIzaSyDDjtVL48of92iZBTzbiyXwVdIJJ9lXIyE",
      authDomain: "canteen-96f48.firebaseapp.com",
      databaseURL: "https://canteen-96f48.firebaseio.com",
      projectId: "canteen-96f48",
      storageBucket: "canteen-96f48.appspot.com",
      messagingSenderId: "27335103862",
      appId: "1:27335103862:web:02b97d7ef8f1f9999f1657",
      measurementId: "G-NKX85R8DP9"
    }
  },
  vapidKey: {
    fuvior:
      "BAex50qilDFd-OUe6NqgeyB9JP_O_Oj0vmydABVYmzw_xtVU0Acpz7sZxuUFdSTuRBA6jAreFBlrTKDr_zIrXls",
    ecard: "BAex50qilDFd-OUe6NqgeyB9JP_O_Oj0vmydABVYmzw_xtVU0Acpz7sZxuUFdSTuRBA6jAreFBlrTKDr_zIrXls"
  }
};
export const ENV = defaultConfig.env;
export const ECARD_PROFILE = defaultConfig.profile.url;
export const HOME_PAGE = defaultConfig.home;
export const ECARD_HOST = defaultConfig.host;
export const API_HOST = defaultConfig.api.host;
export const UPLOAD_IMAGE_HOST = defaultConfig.api.imageHost;
export const UPLOAD_EMPLOYEE_FILE_HOST = defaultConfig.api.importEmployee;
export const DEFAULT_AVATAR_URL = `${defaultConfig.api.host}/images/default-avatar.png`;
export const LOGIN_LOGO = `${defaultConfig.api.host}/logo/${project}/login-logo.png`;
export const HEADER_MENU_LOGO = `${defaultConfig.api.host}/logo/${project}/header-menu.png`;

export const API_HEADERS = {
  product: defaultConfig.api.product,
  token: defaultConfig.api.token,
  authorization: "",
  deviceInfo: {}
};

export const COLOR = defaultConfig.color;
export const FIREBASE_CONFIG = FIREBASE_PROJECT.config[project];
export const FIREBASE_VAPID_KEY = FIREBASE_PROJECT.vapidKey[project];
