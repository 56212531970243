import { ECARD_HOST } from "@/config";
import { routes } from "@/router/routes";

function tableCardJson(item) {
  return {
    id: item.id,
    cardStatus: item.cardStatus,
    organizationName: item.organization?.name || "",
    createdAt: item.createdAt || "",
    owner: {
      id: item.user?.id || "",
      name: item.user?.name || "",
      avatar: item.user?.avatar || "",
      email: item.user?.email || "",
      phone: item.user?.phone || "",
      role: item.user?.role || "",
      title: item.user?.title || "",
      saveCount: item.user?.saveCount || 0,
      viewCount: item.user?.viewCount || 0
    }
  };
}

export const CARD_STATUS = {
  on: "on",
  off: "off",
  inactive: "inactive"
};

export const getCardUrl = (cardId) =>
  `${ECARD_HOST}${routes.cardInfo.path.replace(":cardId", cardId)}`;

export default {
  tableCardJson
};
