import { API_HEADERS, API_HOST } from "@/config";
import apiCode from "../services/api-services/api-code";
import ApiService from "../services/api-services/api-service";

export default class CardApi {
  #apiService = new ApiService(API_HOST, API_HEADERS);

  async getCardListPagination(query) {
    const result = await this.#apiService.get("/card/pagination", query);

    return {
      isSuccess: result.code === apiCode.success,
      status: result.status,
      code: result.code,
      message: result.message,
      data: {
        items: result.data.items || [],
        total: Number(result.data.total) || 0,
        limit: Number(result.data.limit)
      }
    };
  }

  async getCardList(query) {
    const result = await this.#apiService.get("/card", query);
    return {
      isSuccess: result.code === apiCode.success,
      status: result.status,
      code: result.code,
      message: result.message,
      data: {
        items: result.data.items || [],
        total: Number(result.data.total) || 0,
        limit: Number(result.data.limit) || 10
      }
    };
  }

  async getCardDetail(id) {
    const result = await this.#apiService.get(`/card/${id}`);
    return {
      isSuccess: result.code === apiCode.success,
      status: result.status,
      code: result.code,
      message: result.message,
      data: result.data
    };
  }

  async createCards(body, query) {
    const result = await this.#apiService.post("/card/multiple", body, query);
    return {
      isSuccess: result.code === apiCode.success,
      status: result.status,
      code: result.code,
      message: result.message,
      data: {
        items: result.data.items || []
      }
    };
  }

  async updateCard(id, body) {
    const result = await this.#apiService.put(`/card/${id}`, body);
    return {
      isSuccess: result.code === apiCode.success,
      status: result.status,
      code: result.code,
      message: result.message,
      data: result.data
    };
  }

  async removeCard(id) {
    const result = await this.#apiService.remove(`/card/${id}`);
    return {
      ...{ isSuccess: result.code === apiCode.success },
      ...result
    };
  }
  async removeCardOwner(cardId) {
    const path = "/card/:id/owner".replace(":id", cardId);
    const result = await this.#apiService.remove(path);
    return {
      ...{ isSuccess: result.code === apiCode.success },
      ...result
    };
  }
}
