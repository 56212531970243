const ip = "192.168.1.76";

export default {
  env: "debug",
  home: "https://fuvior.com",
  primaryColor: "#BC254A",
  host: `http://${ip}:7605`,
  serverHost: `http://${ip}:7606`,
  profile: {
    host: `http://${ip}:7606`,
    url: `http://${ip}:7606/p/:userId`
  },
  api: {
    host: `http://${ip}:7606`,
    imageHost: `//${ip}:7606/user/upload/avatar`,
    importEmployee: `//${ip}:7606/organization/upload/employee`,
    product: "fuvior-admin",
    token: "df148f1e582a0ad440589013861ef98c32c1dec2877b8b6d71f4792785a94690"
  },
  color: {
    primary: "#47A76D",
    secondary: "#14929F",
    text: "#243A41",
    text2: "#222222",
    border: "#B1B1B1"
  }
};
