<template>
  <div class="mobile-display" style="padding: 20px 16px">
    <div class="page-title">Review</div>
  </div>
  <div class="template-list">
    <a-space class="template-list-container">
      <div :class="template.id === select ? 'template active' : 'template'" v-for="(template, index) in templateList"
        :key="index">
        <div style="position: relative" @click="() => handleSelectTemplate(template.id)">
          <img v-if="template.isPremium" style="position: absolute; top:0; left: -4px"
            src="/assets/icons/template/is-premium-template-ic.svg" />
          <img :src="template.thumbnail" />
        </div>
      </div>
      <div class="buy-premium" @click="handleModalVisible(true)">
        <img src="/assets/images/premium-crown.svg" /><br />
        <label class="text"> Nâng cấp</label>
      </div>
    </a-space>
  </div>
  <a-modal class="text-center" :footer="false" v-model:visible="visible" @ok="handleModalVisible(false)">
    <img src="/assets/icons/template/update-premium-ic.svg" />
    <div class="modal-title">Nâng cấp tài khoản</div>
    <p class="modal-description">
      Bạn muốn trải nghiệm nhiều mẫu e-card đẳng cấp hơn, hãy cho chúng tôi biết điều đó?
    </p>
    <a-button type="primary">
      <a href="tel:0773752875">Nâng cấp ngay</a>
    </a-button>
  </a-modal>
</template>

<script>
import { GET_ACTIVE_TEMPLATE } from "@/core/presentation/actions/template";
import { computed, defineComponent, ref } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  created() {
    this.store.dispatch(GET_ACTIVE_TEMPLATE);
  },
  props: ["select", "items"],
  emits: ["update:select"],
  setup(props, { emit }) {
    const store = useStore();
    const templateList = computed(() => props.items || store.state.template.options);

    const visible = ref(false);
    const handleModalVisible = (value) => (visible.value = value);
    const handleSelectTemplate = (value) => emit("update:select", value);

    return { visible, templateList, store, handleModalVisible, handleSelectTemplate };
  }
});
</script>