export default {
  env: "production",
  home: "https://fuvior.com",
  host: "https://admin.fuvior.com",
  serverHost: "https://id.fuvior.com",
  profile: {
    host: "https://id.fuvior.com",
    url: "https://id.fuvior.com/p/:userId"
  },
  api: {
    host: "https://api.fuvior.com",
    imageHost: "//api.fuvior.com/user/upload/avatar",
    importEmployee: "//api.fuvior.com/organization/upload/employee",
    product: "fuvior-admin",
    token: "df148f1e582a0ad440589013861ef98c32c1dec2877b8b6d71f4792785a94690"
  },
  color: {
    primary: "#47A76D",
    secondary: "#14929F",
    text: "#243A41",
    text2: "#222222",
    border: "#B1B1B1"
  }
};
