import i18n from "@/i18n";
import router from "@/router";
import store from "@/store";
import Antd from "ant-design-vue";

import { createApp } from "vue";
import { createMetaManager, plugin as metaPlugin } from "vue-meta";

import Ecard from "./Ecard.vue";
import Fuvior from "./Fuvior.vue";

import "ant-design-vue/dist/antd.less";
import "../public/assets/css/app.css";

import PageBack from "@/components/common/PageBack.vue";
import FloatButton from "./components/common/FloatButton.vue";
import AppLoading from "./components/common/Loading.vue";
import SaveButton from "./components/common/SaveButton.vue";
import SubmitButton from "./components/common/SubmitButton.vue";
import ToggleIcon from "./components/common/ToggleIcon.vue";

import AdminLayout from "./components/layout/AdminLayout.vue";

import OrganizationInfo from "./components/layout/template/OrganizationInfo.vue";
import QRCodePopup from "./components/layout/template/QRCodePopup.vue";
import ShareSocialPopup from "./components/layout/template/ShareSocialPopup.vue";
import LogoSection from "./components/layout/template/component/LogoSection.vue";
import ReviewOrganizationTemplate from "./components/template/ReviewOrganizationTemplate.vue";
import ReviewUserTemplate from "./components/template/ReviewUserTemplate.vue";
import TemplateList from "./components/template/TemplateList.vue";

import AIcon from "./components/icons/AIcon";

const app = createApp(process.env.VUE_APP_PROJECT === "fuvior" ? Fuvior : Ecard);
app.use(Antd);
app.use(router);
app.use(store);
app.use(i18n);
app.use(createMetaManager(false, { refreshOnceOnNavigation: true }));
app.use(metaPlugin);
app.component("AIcon", AIcon);
app.component("AdminLayout", AdminLayout);
app.component("AppLoading", AppLoading);
app.component("PageBack", PageBack);
app.component("ShareSocialPopup", ShareSocialPopup);
app.component("QRCodePopup", QRCodePopup);
app.component("OrganizationInfo", OrganizationInfo);
app.component("TemplateList", TemplateList);
app.component("SaveButton", SaveButton);
app.component("ReviewUserTemplate", ReviewUserTemplate);
app.component("ReviewOrganizationTemplate", ReviewOrganizationTemplate);
app.component("ToggleIcon", ToggleIcon);
app.component("LogoSection", LogoSection);
app.component("SubmitButton", SubmitButton);
app.component("FloatButton", FloatButton);
window.addEventListener("click", function () {
  if (window.Notification && Notification.permission !== "denied") {
    Notification.requestPermission();
  }
});
router.isReady().then(() => app.mount("#app"));
