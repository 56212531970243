export const tableOrgValue = (values) => {
  return {
    id: values.id || undefined,
    name: values.name || undefined,
    code: values.code || undefined,
    taxNumber: values.taxNumber || undefined,
    email: values.email || undefined,
    cardCount: values.cardCount || undefined,
    phone: values.phone || undefined,
    active: values.active || false
  };
};

export function setFormValues(values) {
  return {
    id: values.id,
    name: values.name,
    code: values.code,
    active: values.active || false,
    taxNumber: values.taxNumber,
    email: values.email,
    phone: values.phone,
    website: values.website,
    address: values.address,
    social: values.social || []
  };
}

export function getFormValues(values) {
  return {
    id: values.id,
    name: values.name,
    code: values.code,
    active: values.active,
    taxNumber: values.taxNumber,
    email: values.email,
    phone: values.phone,
    website: values.website,
    address: values.address,
    selectTemplate: values.selectTemplate,
    social:
      values && values.social
        ? values.social.filter((item) => item.code && item.url && item.active === true)
        : [],
    video: values.video || undefined,
    blog: values.blog || undefined,
    cardLimit: values.cardLimit,
    type: values.type
  };
}

export const ORGANIZATION_TYPE = {
  static: "static",
  dynamic: "dynamic"
};
