<template>
  <a-spin :spinning="loading" tip="Loading...">
    <div style="min-height: 100vh;">
      <AdminHeader />
      <div id="admin-content">
        <slot />
      </div>
    </div>
  </a-spin>
</template>

<script>
import { mapState } from "vuex";
import AdminHeader from "./AdminHeader.vue";

export default {
  name: "AdminLayout",
  computed: {
    ...mapState({
      loading: (state) => state.loading,
    })
  },
  props: {
    title: String
  },
  components: { AdminHeader },
};
</script>
