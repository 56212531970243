import createTrackingUseCase from "@/core/domain/usecases/tracking/create-tracking";

export default {
  socialClick(actions, { trackingId, trackingOrganization, socialId, socialOwnerType }) {
    createTrackingUseCase(trackingId, {
      clickSocial: { socialId, socialOwnerType },
      trackingOrganization
    });
  }
};
