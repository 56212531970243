import profileState from "./state";

export default {
  SAVE_PROFILE_SUCCESS(state = profileState) {
    state.isSuccess = true;
    state.loading = false;
  },
  SAVE_PROFILE_ERROR(state = profileState) {
    state.isSuccess = false;
    state.loading = false;
  },
  GET_PROFILE_DETAIL_FETCHING(state = profileState) {
    state.isFetching = true;
    state.detail = {};
    state.error = "";
  },
  GET_PROFILE_DETAIL_SUCCESS(state = profileState, data) {
    state.detail = data;
    state.isFetching = false;
    state.error = "";
  },
  GET_PROFILE_DETAIL_ERROR(state = profileState, message) {
    state.error = message;
    state.isFetching = false;
    state.detail = {};
  }
};
