export default {
  env: "production",
  home: "https://ecardvip.com",
  host: "https://hi.ecardvip.com",
  serverHost: "https://id.ecardvip.com",
  profile: {
    host: "https://id.ecardvip.com",
    url: "https://id.ecardvip.com/p/:userId"
  },
  api: {
    host: "https://hiapi.ecardvip.com",
    imageHost: "//hiapi.ecardvip.com/user/upload/avatar",
    importEmployee: "//hiapi.ecardvip.com/organization/upload/employee",
    product: "admin",
    token: "2b5fa55f4518e8f3cf810fce6591d347a69027d7916cdd54b6665f06b1a3ffe9"
  },
  color: {
    primary: "#BC254A",
    secondary: "#5c738d",
    text: "#353535",
    text2: "#222222",
    border: "#B1B1B1"
  }
};
