import apiCode from "@/core/data/services/api-services/api-code";
import getProfileUseCase from "@/core/domain/usecases/profile/get-profile";
import saveProfileUseCase from "@/core/domain/usecases/profile/save-profile";
import router from "@/router";
import { routes } from "@/router/routes";
import { ALERT_ERROR } from "../../actions/alert";

export default {
  async saveProfile({ dispatch }, userId) {
    if (!userId) return;

    const result = await saveProfileUseCase(userId);
    if (!result.isSuccess) {
      dispatch(ALERT_ERROR, "Không tìm thấy thông tin danh bạ", { root: true });
    }
  },
  async getProfile({ commit, dispatch }, { value, query }) {
    //id === 0 by pass iframe
    if (!value || value === "0") return commit("GET_PROFILE_DETAIL_SUCCESS", {});

    commit("GET_PROFILE_DETAIL_FETCHING");
    const result = await getProfileUseCase(value, query);
    if (result.isSuccess) {
      return commit("GET_PROFILE_DETAIL_SUCCESS", result.data);
    }

    commit("GET_PROFILE_DETAIL_ERROR", result.message);
    if (result.code === apiCode.notFound) {
      return router.replace(routes.contactUs);
    }
    dispatch(ALERT_ERROR, result.message, { root: true });
  }
};
