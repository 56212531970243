<template>
  <div class="box-thong-tin-dn" v-if="organization">
    <div class="thong-tin-dn">
      <h5 class="title-ten-cty" v-html="organization.name" />
      <p v-if="organization.address">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
          <path
            d="M14 6.66602C14 11.3327 8 15.3327 8 15.3327C8 15.3327 2 11.3327 2 6.66602C2 5.07472 2.63214 3.54859 3.75736 2.42337C4.88258 1.29816 6.4087 0.666016 8 0.666016C9.5913 0.666016 11.1174 1.29816 12.2426 2.42337C13.3679 3.54859 14 5.07472 14 6.66602Z"
            :stroke="iconColor" stroke-linecap="round" stroke-linejoin="round" />
          <path
            d="M8 8.66602C9.10457 8.66602 10 7.77059 10 6.66602C10 5.56145 9.10457 4.66602 8 4.66602C6.89543 4.66602 6 5.56145 6 6.66602C6 7.77059 6.89543 8.66602 8 8.66602Z"
            :stroke="iconColor" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
        <span v-html="organization.address" />
      </p>
      <p v-if="organization.taxNumber">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
          <path
            d="M14.0013 2.66699H2.0013C1.26492 2.66699 0.667969 3.26395 0.667969 4.00033V12.0003C0.667969 12.7367 1.26492 13.3337 2.0013 13.3337H14.0013C14.7377 13.3337 15.3346 12.7367 15.3346 12.0003V4.00033C15.3346 3.26395 14.7377 2.66699 14.0013 2.66699Z"
            :stroke="iconColor" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M0.667969 6.66699H15.3346" :stroke="iconColor" stroke-linecap="round" stroke-linejoin="round" />
        </svg>MST : {{ organization.taxNumber }}
      </p>
      <p v-if="organization.phone">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
          <g clip-path="url(#clip0_767_16924)">
            <path
              d="M14.6669 11.2797V13.2797C14.6677 13.4654 14.6297 13.6492 14.5553 13.8193C14.4809 13.9894 14.3718 14.1421 14.235 14.2676C14.0982 14.3932 13.9367 14.4887 13.7608 14.5482C13.5849 14.6077 13.3985 14.6298 13.2136 14.6131C11.1622 14.3902 9.19161 13.6892 7.46028 12.5664C5.8495 11.5428 4.48384 10.1772 3.46028 8.56641C2.3336 6.82721 1.63244 4.84707 1.41361 2.78641C1.39695 2.60205 1.41886 2.41625 1.47795 2.24082C1.53703 2.0654 1.63199 1.9042 1.75679 1.76749C1.88159 1.63077 2.03348 1.52155 2.20281 1.44675C2.37213 1.37196 2.55517 1.33325 2.74028 1.33307H4.74028C5.06382 1.32989 5.37748 1.44446 5.62279 1.65543C5.8681 1.8664 6.02833 2.15937 6.07361 2.47974C6.15803 3.11978 6.31458 3.74822 6.54028 4.35307C6.62998 4.59169 6.64939 4.85102 6.59622 5.10033C6.54305 5.34964 6.41952 5.57848 6.24028 5.75974L5.39361 6.60641C6.34265 8.27544 7.72458 9.65737 9.39361 10.6064L10.2403 9.75974C10.4215 9.5805 10.6504 9.45697 10.8997 9.4038C11.149 9.35063 11.4083 9.37004 11.6469 9.45974C12.2518 9.68544 12.8802 9.84199 13.5203 9.92641C13.8441 9.97209 14.1399 10.1352 14.3513 10.3847C14.5627 10.6343 14.6751 10.9528 14.6669 11.2797Z"
              :stroke="iconColor" stroke-linecap="round" stroke-linejoin="round" />
          </g>
          <defs>
            <clipPath id="clip0_767_16924">
              <rect width="16" height="16" fill="white" />
            </clipPath>
          </defs>
        </svg>
        <a target="_blank" :href="`tel:${organization.phone}`">
          {{ organization.phone }}
        </a>
      </p>
      <p v-if="organization.email">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
          <path
            d="M2.66536 2.66699H13.332C14.0654 2.66699 14.6654 3.26699 14.6654 4.00033V12.0003C14.6654 12.7337 14.0654 13.3337 13.332 13.3337H2.66536C1.93203 13.3337 1.33203 12.7337 1.33203 12.0003V4.00033C1.33203 3.26699 1.93203 2.66699 2.66536 2.66699Z"
            :stroke="iconColor" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M14.6654 4L7.9987 8.66667L1.33203 4" :stroke="iconColor" stroke-linecap="round"
            stroke-linejoin="round" />
        </svg>
        <a target="_blank" :href="`tel:${organization.email}`">
          {{ organization.email }}
        </a>
      </p>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  props: ["organization", "iconColor"]
});
</script>
