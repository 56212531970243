import { ECARD_HOST } from "@/config";
import { routes } from "@/router/routes";
import QRCode from "qrcode";

export const generateQRCode = (id = 0) => {
  let qrcode = "";
  const url = `${ECARD_HOST}${routes.cardInfo.path}`.replace(":cardId", id);
  const options = {
    errorCorrectionLevel: "M",
    type: "image/png",
    // quality: 0.9,
    margin: 1,
    width: 570
  };

  QRCode.toDataURL(url, options, (err, _qrcode) => {
    if (err) throw err;
    qrcode = _qrcode;
  });

  return qrcode;
};
