function toJson(values = {}) {
  return {
    id: values.id,
    isRead: values.isRead,
    userId: values.userId,
    title: values.title,
    body: values.body,
    type: values.type,
    createdAt: values.createdAt,
    createdBy: values.createdBy,
    createdText: values.createdText
  };
}

export const NOTIFICATION_TYPE = {
  message: "message",
  remind: "remind",
  contact: "contact"
};

export default {
  toJson
};
