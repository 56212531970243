import createCardsUseCase from "@/core/domain/usecases/card/create-cards";
import getCardDetailUseCase from "@/core/domain/usecases/card/get-card-detail";
import getCardListUseCase from "@/core/domain/usecases/card/get-card-list";
import removeCardOwnerUseCase from "@/core/domain/usecases/card/remove-card-owner";
import updateCardUseCase from "@/core/domain/usecases/card/update-card";

import { ALERT_ERROR } from "../../actions/alert";

export default {
  async getCardList({ commit, dispatch }, query) {
    commit("GET_CARD_FETCHING");
    const result = await getCardListUseCase(query);
    if (result.isSuccess)
      return commit("GET_CARD_SUCCESS", {
        total: result.data.total,
        pageSize: result.data.limit,
        data: result.data.items
      });

    dispatch(ALERT_ERROR, result.message, { root: true });
    return commit("GET_CARD_ERROR", result.message);
  },
  async getCardDetail({ commit, dispatch }, id) {
    if (!id) return commit("GET_CARD_DETAIL_SUCCESS", {});

    commit("GET_CARD_DETAIL_FETCHING");
    const result = await getCardDetailUseCase(id);
    if (result.isSuccess) {
      return commit("GET_CARD_DETAIL_SUCCESS", result.data);
    }

    dispatch(ALERT_ERROR, result.message, { root: true });
    return commit("GET_CARD_DETAIL_ERROR", result.message);
  },
  async removeCard({ commit, dispatch }, id) {
    commit("SET_CARD_LOADING");
    const result = await removeCardOwnerUseCase(id);
    if (result.isSuccess) return commit("REMOVE_CARD_SUCCESS", result.data);

    dispatch(ALERT_ERROR, result.message, { root: true });
    return commit("REMOVE_CARD_ERROR", result.message);
  },
  async updateCard({ commit, dispatch }, data) {
    commit("SET_CARD_LOADING");
    const result = await updateCardUseCase(data);
    if (result.isSuccess) return commit("UPDATE_CARD_SUCCESS", result.data);

    dispatch(ALERT_ERROR, result.message, { root: true });
    return commit("UPDATE_CARD_ERROR", result.message);
  },
  async createCards({ commit, dispatch }, values) {
    commit("SET_CARD_LOADING");
    const result = await createCardsUseCase(values.info, values.query);
    if (result.isSuccess) return commit("SET_NEW_CARDS", result.data.items);

    dispatch(ALERT_ERROR, result.message, { root: true });
    return commit("CREATE_CARDS_ERROR", result.message);
  },
  async setNewCards({ commit }) {
    commit("SET_NEW_CARDS", []);
  }
};
