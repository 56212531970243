import menuByRole from "@/core/domain/helpers/menu-helper";
import RoleModel from "@/core/domain/models/role-model";
import { removeLoginAccount, setLoginAccount } from "@/core/utils/auth";
import accountState from "./state";

export default {
  SET_LOADING(state = accountState) {
    state.loading = true;
    state.isSuccess = false;
  },
  ACCOUNT_LOGIN_SUCCESS(state = accountState, data) {
    setLoginAccount(data);

    const role = data.user.role || RoleModel.ROLE.user;
    state.loginAccount = data.user;
    state.loading = false;
    state.menuList = menuByRole[role] || [];
  },
  ACCOUNT_LOGIN_ERROR(state = accountState) {
    state.loading = false;
  },
  ACCOUNT_LOGOUT_SUCCESS(state = accountState) {
    state.loginAccount = {};
    removeLoginAccount();
  },
  SET_SUCCESS_STATUS(state = accountState, status) {
    state.isSuccess = status;
    state.loading = false;
  },
  SET_CURRENT_LOGIN_ACCOUNT(state = accountState, loginAccount) {
    state.loginAccount = loginAccount;
    state.menuList = loginAccount ? menuByRole[loginAccount.role] : [];
  },
  REGISTER_ACCOUNT_SUCCESS(state = accountState) {
    state.isSuccess = true;
    state.loading = false;
  },
  REGISTER_ACCOUNT_ERROR(state = accountState, message) {
    state.error = message;
    state.isSuccess = false;
    state.loading = false;
  }
};
