import { USER_TYPE } from "@/core/domain/models/user-model";

export default {
  total: 0,
  pageSize: 10,
  data: [],
  isFetching: false,
  error: "",
  isSuccess: false,
  loading: false,
  category: [
    { label: "Cá nhân", value: USER_TYPE.personal },
    { label: "Tổ chức", value: USER_TYPE.organization }
  ],
  detail: {},
  columns: [
    {
      title: "STT",
      dataIndex: "key",
      key: "key",
      align: "center",
      fixed: "left",
      width: 60
    },
    {
      title: "ID",
      key: "id",
      dataIndex: "id",
      ellipsis: true,
      width: 120,
      fixed: "left"
    },
    {
      title: "Họ tên",
      key: "name",
      dataIndex: "name",
      ellipsis: true
    },
    {
      title: "Email",
      key: "email",
      dataIndex: "email"
    },
    {
      title: "SĐT",
      key: "phone",
      dataIndex: "phone"
    },
    {
      title: "Vai trò",
      key: "role",
      dataIndex: "role"
    },
    {
      title: "QR",
      key: "qrcode",
      dataIndex: "qrcode",
      align: "center"
    },
    {
      title: "Lượt lưu",
      key: "saveCount",
      dataIndex: "saveCount",
      align: "right"
    },
    {
      title: "Lượt xem",
      key: "viewCount",
      dataIndex: "viewCount",
      align: "right"
    },
    {
      title: "Tên tổ chức",
      key: "organization",
      dataIndex: "organization",
      ellipsis: true
    },
    {
      title: "Trạng thái",
      key: "status",
      dataIndex: "status"
    },
    {
      title: "Thao tác",
      key: "tableAction",
      fixed: "right",
      width: 150,
      align: "center"
    }
  ],
  uploadAvatar: {
    id: "",
    url: "",
    isSuccess: false
  }
};
