const ip = "localhost";

export default {
  env: "local",
  home: "https://ecardvip.com",
  host: `http://${ip}:7601`,
  serverHost: `http://${ip}:7602`,
  profile: {
    host: `http://${ip}:7602`,
    url: `http://${ip}:7602/p/:userId`
  },
  api: {
    host: `http://${ip}:7602`,
    imageHost: `//${ip}:7602/user/upload/avatar`,
    importEmployee: `//${ip}:7602/organization/upload/employee`,
    product: "admin",
    token: "2b5fa55f4518e8f3cf810fce6591d347a69027d7916cdd54b6665f06b1a3ffe9"
  },
  color: {
    primary: "#BC254A",
    secondary: "#5c738d",
    text: "#353535",
    text2: "#222222",
    border: "#B1B1B1"
  }
};
