import { API_HEADERS, API_HOST } from "@/config";
import apiCode from "../services/api-services/api-code";
import ApiService from "../services/api-services/api-service";
export default class ProfileApi {
  #apiService = new ApiService(API_HOST, API_HEADERS);

  async saveProfile(userId) {
    window.location.href = `${API_HOST}/profile/${userId}/save?token=${API_HEADERS.token}&product=${API_HEADERS.product}`;

    return {
      isSuccess: true
    };
  }

  async getProfile(value, query) {
    const result = await this.#apiService.get(`/profile/${value}`, query);
    return {
      isSuccess: result.code === apiCode.success,
      status: result.status,
      code: result.code,
      message: result.message,
      data: result.data
    };
  }
}
