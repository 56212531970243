import { tableOrgValue } from "@/core/domain/models/exchange-contact-model";
import exchangeContactState from "./state";

export default {
  GET_EXCHANGE_CONTACT_FETCHING(state = exchangeContactState) {
    state.isFetching = true;
    state.data = [];
    state.isSuccess = false;
  },
  GET_EXCHANGE_CONTACT_ERROR(state = exchangeContactState, message) {
    state.isFetching = false;
    state.error = message;
  },
  GET_EXCHANGE_CONTACT_SUCCESS(state = exchangeContactState, { total, data, pageSize }) {
    state.isFetching = false;
    state.error = "";
    state.total = total;
    state.pageSize = pageSize;
    state.data = data;
  },
  CREATE_EXCHANGE_CONTACT_SUCCESS(state = exchangeContactState, values) {
    state.data.unshift(tableOrgValue(values));
    state.loading = false;
    state.isSuccess = true;
  },
  CREATE_EXCHANGE_CONTACT_ERROR(state = exchangeContactState, message) {
    state.error = message;
    state.loading = false;
    state.isSuccess = false;
  },
  REMOVE_EXCHANGE_CONTACT_SUCCESS(state = exchangeContactState, id) {
    const index = state.data.findIndex((item) => item.id === id);
    if (index !== -1) {
      state.data.splice(index, 1);
    }
    state.isSuccess = true;
    state.loading = false;
  },
  REMOVE_EXCHANGE_CONTACT_ERROR(state = exchangeContactState, message) {
    state.error = message;
    state.loading = false;
    state.isSuccess = false;
  },
  SET_EXCHANGE_CONTACT_LOADING(state = exchangeContactState) {
    state.isSuccess = false;
    state.loading = true;
  }
};
