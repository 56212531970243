import notificationModel from "@/core/domain/models/notification-model";
import notificationState from "./state";

export default {
  GET_NOTIFICATION_FETCHING(state = notificationState) {
    state.isFetching = true;
    state.data = [];
  },
  GET_NOTIFICATION_ERROR(state = notificationState, message) {
    state.isFetching = false;
    state.error = message;
  },
  GET_NOTIFICATION_SUCCESS(state = notificationState, { total, data, pageSize, unreadCount }) {
    state.isFetching = false;
    state.error = "";
    state.total = total;
    state.pageSize = pageSize;
    state.data = data;
    state.unreadCount = unreadCount;
  },
  GET_MORE_NOTIFICATION_SUCCESS(state = notificationState, { data }) {
    state.error = "";
    state.data = state.data.concat(data);
    state.loading = false;
  },
  SET_NOTIFICATION_LOADING(state = notificationState) {
    state.isSuccess = false;
    state.loading = true;
  },
  CREATE_NOTIFICATION_SUCCESS(state = notificationState) {
    // state.data.unshift(templateModel.tableValues(values));
    state.isSuccess = true;
    state.loading = false;
  },
  CREATE_NOTIFICATION_ERROR(state = notificationState, message) {
    state.error = message;
    state.isSuccess = false;
    state.loading = false;
  },
  SET_READ_NOTIFICATION_SUCCESS(state = notificationState, values) {
    const index = state.data.findIndex((item) => item.id === values.id);
    state.data.splice(index, 1, notificationModel.toJson(values));
    state.isSuccess = true;
    state.loading = false;
    state.unreadCount = state.unreadCount - 1;
  },
  SET_READ_NOTIFICATION_ERROR(state = notificationState, message) {
    state.error = message;
    state.isSuccess = false;
    state.loading = false;
  },
  GET_NEW_NOTIFICATION_SUCCESS(state = notificationState, notification) {
    state.data.unshift(notification);
    state.unreadCount = state.unreadCount + 1;
    state.newNotification.visible = true;
    state.newNotification.data = notification;
  },
  SET_NEW_NOTIFICATION_VISIBLE(state = notificationState, visible) {
    state.newNotification.visible = visible;
  }
};
