export default {
  error: "",
  isFetching: false,
  totalUserCount: "0",
  trackingResumeCount: {
    viewProfile: "0",
    saveProfile: "0",
    lead: "0",
    exchangeContact: "0"
  },
  socialLink: [],
  resumeChart: {
    labels: [],
    datasets: {
      viewProfile: [],
      saveProfile: [],
      lead: [],
      exchangeContact: []
    }
  },
  viewSocial: {
    labels: [],
    data: []
  },
  viewDevice: {
    labels: [],
    data: []
  },
  socialLinkColumns: [
    {
      title: "Tên hiển thị",
      dataIndex: "code",
      key: "code"
      // width: 1500
    },
    {
      title: "Link",
      dataIndex: "url",
      key: "url",
      ellipsis: true
    },
    {
      title: "Lượt click",
      dataIndex: "count",
      key: "count"
    }
  ]
};
