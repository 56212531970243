export function convertSocialUrl(social = []) {
  social = JSON.parse(JSON.stringify(social));

  for (const link of social) {
    if (!link?.url.toLowerCase().includes("http")) {
      link.url = "https://" + link.url;
    }
  }

  return social;
}
