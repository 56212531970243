export const tableOrgValue = (values) => {
  return {
    id: values.id || undefined,
    name: values.name || undefined,
    email: values.email || undefined,
    phone: values.phone || undefined
  };
};

export const createExchangeContactBody = ({ data, userId, organizationId }) => {
  return {
    userId,
    organizationId,
    data: {
      name: data.name,
      email: data.email,
      phone: data.phone,
      companyName: data.companyName,
      address: data.address
    }
  };
};

export const initExchangeContactState = {
  active: false,
  field: {
    name: false,
    email: false,
    phone: false,
    companyName: false,
    address: false
  }
};
export const EXCHANGE_CONTACT_REQUIRED_FIELDS = ["name", "phone"];

export const EXCHANGE_CONTACT_FIELD_LIST = ["name", "phone", "email", "address", "companyName"];
