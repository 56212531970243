export function getLoginAccount() {
  try {
    const data = JSON.parse(sessionStorage.getItem("loginAccount"));
    if (data && data.user) return data.user;
  } catch (error) {
    return null;
  }
}

export function getLoginToken() {
  try {
    const data = JSON.parse(sessionStorage.getItem("loginAccount"));
    if (data && data.token) return data.token;
  } catch (error) {
    return "";
  }
}

export function setLoginAccount(data) {
  sessionStorage.setItem("loginAccount", JSON.stringify(data));
}

export function removeLoginAccount() {
  sessionStorage.removeItem("loginAccount");
}
