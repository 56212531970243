<template>
  <div v-if="loading" class="loading">
    <a-spin :spinning="loading" />
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "AppLoading",
  props: ["loading"]
});
</script>
<style scoped>
.loading {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
}
</style>
