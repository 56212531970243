import cardModel from "@/core/domain/models/card-model";
import employeeState from "./state";

export default {
  GET_EMPLOYEE_FETCHING(state = employeeState) {
    state.isFetching = true;
  },
  GET_EMPLOYEE_ERROR(state = employeeState, message) {
    state.isFetching = false;
    state.error = message;
  },
  GET_EMPLOYEE_SUCCESS(state = employeeState, { total, data, pageSize }) {
    state.isFetching = false;
    state.error = "";
    state.total = total;
    state.pageSize = pageSize;
    state.data = data;
  },
  SET_EMPLOYEE_LOADING(state = employeeState) {
    state.isSuccess = false;
    state.loading = true;
  },
  CREATE_EMPLOYEE_SUCCESS(state = employeeState, values) {
    state.data.unshift(cardModel.tableCardJson(values));
    state.loading = false;
    state.isSuccess = true;
  },
  CREATE_EMPLOYEE_ERROR(state = employeeState, message) {
    state.error = message;
    state.loading = false;
  },
  UPDATE_EMPLOYEE_SUCCESS(state = employeeState, values) {
    const index = state.data.findIndex((item) => item.id === values.id);
    state.data.splice(index, 1, cardModel.tableCardJson(values));
    state.loading = false;
    state.isSuccess = true;
  },
  UPDATE_EMPLOYEE_ERROR(state = employeeState, message) {
    state.error = message;
    state.loading = false;
  },
  REMOVE_EMPLOYEE_SUCCESS(state = employeeState, id) {
    state.data = state.data.filter((item) => item.id !== id);
    state.loading = false;
    state.isSuccess = true;
  },
  REMOVE_EMPLOYEE_ERROR(state = employeeState, message) {
    state.error = message;
    state.loading = false;
  },
  GET_EMPLOYEE_DETAIL_FETCHING(state = employeeState) {
    state.isSuccess = false;
    state.isFetching = true;
    state.detail = {};
  },
  GET_EMPLOYEE_DETAIL_SUCCESS(state = employeeState, data) {
    state.detail = data;
    state.isSuccess = true;
    state.isFetching = false;
  },
  GET_EMPLOYEE_DETAIL_ERROR(state = employeeState, message) {
    state.error = message;
    state.isSuccess = false;
    state.isFetching = false;
    state.detail = {};
  }
};
